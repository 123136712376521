import { IconType } from "react-icons";

import {
  FaHome,
  FaAppStoreIos,
  FaDatabase,
  FaUsersCog,
  FaUserShield,
  FaQuestionCircle
} from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import { MdPersonSearch } from "react-icons/md";
import { AppAuthRequiredRole } from "../../app/types/appType";

export interface NavItemModel {
  icon: IconType;
  text: string;
  href: string;
  strict?: boolean;
  requiredRole?: AppAuthRequiredRole;
  subNav?: Array<NavItemModel>;
}

export const navItems: Array<NavItemModel> = [
  {
    icon: FaHome,
    text: "Home",
    href: "/",
    strict: true,
    requiredRole: ["User.Write", "User.Read"]
  },
  {
    icon: GoUnverified,
    text: "Unverified Emails",
    href: "/unverified-emails",
    requiredRole: ["User.Write", "User.Read"]
  },
  {
    icon: MdAdminPanelSettings,
    text: "Admin",
    href: "/admin",
    requiredRole: ["User.Write", "User.Read"],
    subNav: [
      {
        icon: FaAppStoreIos,
        text: "Apps",
        href: "/admin/apps",
        requiredRole: ["RefApp.Write", "RefApp.Read"]
      },
      {
        icon: MdPersonSearch,
        text: "Demographics",
        href: "/admin/demographics",
        requiredRole: ["RefDemographic.Write", "RefDemographic.Read"]
      },
      {
        icon: FaDatabase,
        text: "Domains",
        href: "/admin/domains",
        requiredRole: ["RefDomain.Read", "RefDomain.Write"]
      },
      {
        icon: FaUsersCog,
        text: "Roles",
        href: "/admin/roles"
      }
    ]
  }
  // Temporarily hide the consent > questions section
  // {
  //   icon: FaUserShield,
  //   text: "Consent",
  //   href: "/consent",
  //   requiredRole: ["User.Write", "User.Read"],
  //   subNav: [
  //     {
  //       icon: FaQuestionCircle,
  //       text: "Questions",
  //       href: "/consent/questions",
  //       requiredRole: ["Consent.RefQuestion.Read", "Consent.RefQuestion.Write"]
  //     }
  //   ]
  // }
];
