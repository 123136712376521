import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { ExtendedClaimsType } from "../types";
import { msalConfig } from "./authConfig";

export const getScopes = () => {
  //return ["user_impersonation"];
  return ["PIM.UIAccess"].map((scope) => `${process.env.REACT_APP_PIM_APP_ID_URI}/${scope}`);
};
export const acquireAccessToken = async (): Promise<string> => {
  let instance = globalThis.msalInstance;
  if (!instance) {
    instance = new PublicClientApplication(msalConfig);
  }

  const accounts = instance.getAllAccounts();

  let token = "";
  const scopes = getScopes();
  try {
    const authResult = await instance.acquireTokenSilent({
      scopes,
      account: accounts[0],
    });
    token = authResult.accessToken;
  } catch (error) {
    // in case if silent token acquisition fails, fallback to an interactive method
    if (error instanceof InteractionRequiredAuthError) {
      if (accounts.length) {
        try {
          await instance.acquireTokenRedirect({
            scopes,
          });
        } catch (error) {
          console.log("acquireTokenRedirect - acquiring token", { error });
        }
      }
    }
  }
  return token;
};

export const getIdTokenClaims = (account: AccountInfo | null) => {
  return account?.idTokenClaims as ExtendedClaimsType;
};
