import React, { FC } from "react";
import { convertUtcToLocal } from "../../app/helpers/dateHelper";

interface IProps {
  labelValueList: Array<{ label: string; value: any }>;
  auditFieldsObject?: {
    row_created_datetime_utc: string;
    row_created_user_id: number;
    row_created_by_user_name: string;
    row_modified_datetime_utc: string;
    row_modified_user_id: number;
    row_modified_by_user_name: string;
  };
}

const TooltipLabelValue: FC<IProps> = ({ labelValueList, auditFieldsObject }) => {
  return (
    <table>
      <tbody>
        {labelValueList.map((m, i) => (
          <tr key={i}>
            <th style={{ whiteSpace: "nowrap" }} align="left">
              {m.label}
            </th>
            <td style={{ whiteSpace: "nowrap" }}>: {m.value}</td>
          </tr>
        ))}
        {auditFieldsObject && (
          <>
            <tr>
              <th style={{ whiteSpace: "nowrap" }} align="left">
                Created By
              </th>
              <td style={{ whiteSpace: "nowrap" }}>
                : {auditFieldsObject.row_created_by_user_name}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }} align="left">
                Created Date
              </th>
              <td style={{ whiteSpace: "nowrap" }}>
                : {convertUtcToLocal(auditFieldsObject.row_created_datetime_utc)}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }} align="left">
                Modified By
              </th>
              <td style={{ whiteSpace: "nowrap" }}>
                : {auditFieldsObject.row_modified_by_user_name}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }} align="left">
                Modified Date
              </th>
              <td style={{ whiteSpace: "nowrap" }}>
                : {convertUtcToLocal(auditFieldsObject.row_modified_datetime_utc)}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default TooltipLabelValue;
