import { pimApi } from "./index";
import type { RefAppDto, RefAppParams } from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getRefAppList: builder.query<Array<RefAppDto>, void>({
      query: () => `/refapp/list`,
      providesTags: ["RefApp"]
    }),
    putRefApp: builder.mutation<
      void,
      { ref_app_id: number; model: RefAppParams }
    >({
      query: (params) => ({
        url: "/refapp",
        method: "PUT",
        body: { ...params.model, ref_app_id: params.ref_app_id },
        responseHandler: "text"
      }),
      invalidatesTags: ["RefApp", "AppSSOUser"]
    }),
    postRefApp: builder.mutation<void, { model: RefAppParams }>({
      query: (params) => ({
        url: `/refapp`,
        method: "POST",
        body: params.model,
        responseHandler: "text"
      }),
      invalidatesTags: ["RefApp", "AppSSOUser"]
    })
  }),
  overrideExisting: false
});

export const {
  useGetRefAppListQuery,
  usePutRefAppMutation,
  usePostRefAppMutation
} = extendedApi;
