import { pimApi } from "./index";
import { RefAppDto } from "../types";
import { string } from "yup";
import { PutUserDemographicParamDto, UserDemographicDto } from "../types/userDemographicTypes";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDemographicByRefUserId: builder.query<Array<UserDemographicDto>, number>({
      query: (refUserId) => `/UserDemographic/user/${refUserId}`,
      providesTags: ["UserDemographic"],
    }),
    putUserDemographicByRefUserId: builder.mutation<any, PutUserDemographicParamDto>({
      query: (param) => ({
        url: `/UserDemographic/user/${param.ref_user_id}`,
        method: "PUT",
        body: param.demographicList,
        responseHandler: (response) => response.text()
      }),
    }),
    postUserDemographicByRefUserId: builder.mutation<void, PutUserDemographicParamDto>({
      query: (param) => ({
        url: `/UserDemographic/user/${param.ref_user_id}`,
        method: "POST",
        body: param.demographicList,
        responseHandler: (response) => response.text()
      }),
      // invalidatesTags: ["UserDemographic", "User"],
    }),
  }),
  overrideExisting: false,
});

export const { 
  useGetUserDemographicByRefUserIdQuery, 
  usePutUserDemographicByRefUserIdMutation,
  usePostUserDemographicByRefUserIdMutation,
  useLazyGetUserDemographicByRefUserIdQuery,
  
} = extendedApi;
