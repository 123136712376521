import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import { convertUtcToLocal } from "../../app/helpers/dateHelper";
import { useDebounce } from "../../app/helpers/stringHelper";
import { useGetUnverifiedUserListQuery } from "../../app/services/pim/api/user";
import { UnverifiedUserDto } from "../../app/services/pim/types";
import CustomTable from "../../components/CustomTable";
import PanelCard from "../../components/PanelCard";

const headers: Column<any>[] = [
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "App Alias",
    accessor: "app_alias"
  },
  {
    Header: "SSO Object ID",
    accessor: "sso_object_id"
  },
  {
    Header: "User Created At",
    accessor: "user_created_datetime_utc",
    Cell: ({ row: { original } }: { row: { original: UnverifiedUserDto } }) => {
      return <>{convertUtcToLocal(original.user_created_datetime_utc)}</>;
    }
  }
];

const UnverifiedEmails = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [search, setSearch] = useState("");
  const [searchDebounce, setSearchDebounce] = useState("");
  const debouncedSearch = useDebounce(search, 400);
  const unverifiedUserListResult = useGetUnverifiedUserListQuery(
    new URLSearchParams({
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      q: searchDebounce
    }).toString()
  );
  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <PanelCard header="Unverified Emails" w="full">
      <CustomTable
        variant="table"
        headers={headers}
        isLoading={unverifiedUserListResult.isLoading}
        isFetching={unverifiedUserListResult.isFetching}
        data={unverifiedUserListResult.data?.data ?? []}
        pageCount={unverifiedUserListResult.data?.total_pages || 0}
        pageSize={unverifiedUserListResult.data?.page_size || pageSize}
        totalRecords={unverifiedUserListResult.data?.total_records || 0}
        showRecordCount
        search={search}
        tableSort
        onPageChange={(index) => {
          setPageNumber(index);
        }}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        onPageSearch={(search) => {
          setPageNumber(1);
          setSearch(search);
        }}
        onSort={() => {}}
        rowDisabledOnTrue={(row) => Boolean(row.disabled_flag)}
      />
    </PanelCard>
  );
};

export default UnverifiedEmails;
