import { Box, Flex, HStack, Stack, Heading, SimpleGrid, Text, Skeleton } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useLazyGetAvatarByEmailQuery } from "../../app/services/pim/api/avatar";
import { useLazyGetUserDemographicByRefUserIdQuery } from "../../app/services/pim/api/userDemographics";
import { UserDemographicDto } from "../../app/services/pim/types/userDemographicTypes";
import { useAppSelector } from "../../app/state/hooks";
import Avatar from "../../components/Avatar/Avatar";
import EditUserDemographicModal from "./EditUserDemographicModal";
import UploadAvatarButton from "./UploadAvatarButton";

interface ProfileProps {}

export const convertDemographicNameToLabel = (name: string) => {
  const wordsArray = name.split("_");
  const label = wordsArray.map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1));
  return label.join(" ") ?? "";
};

const Profile: FunctionComponent<ProfileProps> = () => {
  const [avatar, setAvatar] = useState("");
  const { logonUser } = useAppSelector((s) => s.user);

  const [getUserDemogTrigger, { data, isLoading, isFetching }] =
    useLazyGetUserDemographicByRefUserIdQuery();

  const [avatarQueryTrigger, avatarQuery] = useLazyGetAvatarByEmailQuery();

  useEffect(() => {
    (async () => {
      if (logonUser?.email) {
        await avatarQueryTrigger(logonUser.email);
      }
    })();
  }, [avatarQueryTrigger, logonUser?.email]);

  useEffect(() => {
    (async () => {
      if (logonUser?.ref_user_id) {
        await getUserDemogTrigger(logonUser.ref_user_id);
      }
    })();
  }, [getUserDemogTrigger, logonUser?.ref_user_id]);

  useEffect(() => {
    if (!avatarQuery.isFetching && avatarQuery.data) {
      const img = avatarQuery.data.toString();
      setAvatar(img);
    }
  }, [avatarQuery.data, avatarQuery.isFetching]);

  return (
    <HStack spacing={8} justifyContent="center" alignItems="flex-start">
      {(isFetching && isLoading) ||
      !logonUser ||
      (avatarQuery.isFetching && avatarQuery.isLoading) ? (
        <Stack mt={1}>
          {[...Array(5)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Box p={5} shadow="md" borderWidth="1px" textAlign="center">
            <Avatar src={avatar} />
            {logonUser && (
              <UploadAvatarButton
                title="Change"
                logonUser={logonUser}
                onSuccess={() => {
                  avatarQueryTrigger(logonUser.email);
                }}
              />
            )}
            <Box>
              <Text fontSize={16} fontWeight="medium">
                {logonUser?.email}
              </Text>
              <Text fontSize={12}>Email Address</Text>
            </Box>
          </Box>
          <Box p={5} shadow="md" borderWidth="1px">
            <Heading as="h5" size="md">
              Profile
            </Heading>
            <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={90} pt={6}>
              {data &&
                (data.length > 0 ? (
                  data.map((u: UserDemographicDto, i) => (
                    <Box height="80px" key={i}>
                      <Text fontSize="sm" fontWeight="bold">
                        {convertDemographicNameToLabel(u.demographic_name)}
                      </Text>
                      <Text minW={100}>
                        {u.demographic_value.length > 0 ? u.demographic_value : "-"}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <>
                    <Box height="80px">
                      <Text fontSize="sm" fontWeight="bold">
                        First Name
                      </Text>
                      <Text minW={100}>-</Text>
                    </Box>
                    <Box height="80px">
                      <Text fontSize="sm" fontWeight="bold">
                        Last Name
                      </Text>
                      <Text minW={100}>-</Text>
                    </Box>
                    <Box height="80px">
                      <Text fontSize="sm" fontWeight="bold">
                        Middle Name
                      </Text>
                      <Text minW={100}>-</Text>
                    </Box>
                  </>
                ))}
            </SimpleGrid>
            <Flex height="80px" alignItems="center" justifyContent="flex-end" maxH="62px">
              <EditUserDemographicModal
                title="Edit"
                refUserId={logonUser?.ref_user_id || 0}
                onSuccess={() => getUserDemogTrigger(logonUser.ref_user_id)}
              />
            </Flex>
          </Box>
        </>
      )}
    </HStack>
  );
};

export default Profile;
