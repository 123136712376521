import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { Select as CRSelect } from "chakra-react-select";
import { FC, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGetRefDemographicForAppAssignListQuery } from "../../../app/services/pim/api/refDemographic";
import { RefAppDto } from "../../../app/services/pim/types";
import { usePostAppDemographicMutation } from "../../../app/services/pim/api/appDemographic";

const FormSchema = Yup.object().shape({
  ref_demographic_id: Yup.number()
    .label("Demographic")
    .typeError("Demographic is required")
});

interface IProps {
  refApp: RefAppDto;
}

const AssignDemographicModal: FC<IProps> = ({ refApp }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryResult = useGetRefDemographicForAppAssignListQuery(
    refApp.ref_app_id,
    {
      skip: !refApp.ref_app_id
    }
  );

  const [postAsync, postStatus] = usePostAppDemographicMutation();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    resetForm,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      ref_app_id: refApp.ref_app_id,
      ref_demographic_id: null,
      disabled_flag: false,
      required_field_flag: false
    },
    onSubmit: (values) => {
      postAsync(values);
    }
  });

  useEffect(() => {
    if (postStatus.isSuccess) {
      resetForm();
      onClose();
    }
  }, [postStatus]);

  const onModalClose = () => {
    resetForm();
    onClose();
  };
  return (
    <>
      <Button
        ml={3}
        leftIcon={<AddIcon />}
        boxShadow="sm"
        size="sm"
        onClick={onOpen}
        disabled={Boolean(refApp.disabled_flag)}
      >
        Assign Demographic
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Assign Demographic - {`${refApp.app_alias}`.toUpperCase()}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              <VStack align="stretch" spacing={3}>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.ref_demographic_id && touched.ref_demographic_id
                  }
                >
                  <FormLabel htmlFor="ref_demographic_id">
                    Choose Demographic
                  </FormLabel>
                  <CRSelect
                    id="ref_demographic_id"
                    name="ref_demographic_id"
                    useBasicStyles
                    isLoading={queryResult.isLoading || queryResult.isFetching}
                    isDisabled={queryResult.isLoading || queryResult.isFetching}
                    options={queryResult.data?.map((m) => ({
                      value: m.ref_demographic_id,
                      label: m.demographic_name
                    }))}
                    onChange={(option) =>
                      setFieldValue("ref_demographic_id", option?.value)
                    }
                  />
                  <FormErrorMessage>
                    {errors.ref_demographic_id}
                  </FormErrorMessage>
                </FormControl>
                <Checkbox
                  id="required_field_flag"
                  name="required_field_flag"
                  isChecked={Boolean(values.required_field_flag)}
                  onChange={handleChange}
                >
                  Required
                </Checkbox>
                <Checkbox
                  id="disabled_flag"
                  name="disabled_flag"
                  isChecked={Boolean(values.disabled_flag)}
                  onChange={handleChange}
                >
                  Disabled
                </Checkbox>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onModalClose}
                mr={3}
                disabled={postStatus.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={postStatus.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignDemographicModal;
