import {
  Box,
  Button,
  Heading,
  HStack,
  VStack,
  Icon,
  Text,
  Spinner,
  useInterval,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { appColors } from "../../app/theme";
import { PostUserEmailVerify } from "../../app/services/pim/types/graphTypes";

type ConfirmEmailType = {
  isLoading: boolean;
  data: PostUserEmailVerify;
};
const ConfirmEmail = ({ data, isLoading }: ConfirmEmailType) => {
  const { message, redirect_url, is_success, previously_verified } = data;
  const [count, setCount] = useState<number>(10);

  const navigateTo = () => {
    window.location.href = redirect_url;
  };

  useInterval(() => {
    if (!isLoading) setCount(count - 1);
  }, 1000);

  useEffect(() => {
    if (count <= 1) navigateTo();
  }, [count]);

  return (
    <VStack height="100vh" w="full" padding="6px">
      <HStack w="full" height="100vh" flexDirection="column">
        <Box w="full" bg={appColors.brand.main.default} h="50%" />
        <Box
          minW="40%"
          bg="gray.100"
          minH="350px"
          pos="absolute"
          top="calc((100vh / 2) / 2)"
          borderRadius="7px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p="5px, 5px"
          shadow="md"
        >
          {isLoading ? (
            <>
              <Spinner size="lg" />
              <Heading pt="3" size="md" color="gray.600">
                Processing...
              </Heading>
              <Text size="sm" color="GrayText" pt="3">
                Please wait while the request is being processed...
              </Text>
            </>
          ) : (
            <>
              {previously_verified ? (
                <CheckCircleIcon w="80px" h="80px" color={appColors.brand.main.default} />
              ) : !is_success ? (
                <Icon as={MdCancel} w="80px" h="80px" color="red.500" />
              ) : (
                <CheckCircleIcon w="80px" h="80px" color={appColors.brand.main.default} />
              )}

              {previously_verified ? (
                <Heading pt="3" size="md" color="gray.600">
                  {message}
                </Heading>
              ) : (
                <>
                  <Heading pt="3" size="md" color="gray.600">
                    {!is_success ? "We couldn't verify your email!" : "Verified!"}
                  </Heading>
                  <Text size="sm" color="GrayText" pt="3" maxW="450px">
                    {message}
                  </Text>
                </>
              )}

              <Box pt="5" alignItems="center" display="flex" flexDirection="column">
                <Text size="sm" p="5" fontSize="14px" fontWeight="medium">
                  Please click below if you are not redirected in{" "}
                  <Text as="span" fontWeight="bold">
                    {count}
                  </Text>{" "}
                  seconds.
                </Text>
                <Button colorScheme="brand.main" size="sm" onClick={navigateTo}>
                  Continue
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Box w="full" bg="#f2f2f2" h="50%" m="0" />
      </HStack>
    </VStack>
  );
};

export default ConfirmEmail;
