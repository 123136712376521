import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import Header from "./components/Header";
import {
  UnauthenticatedTemplate,
  useMsal,
  MsalProvider,
  useIsAuthenticated,
  AuthenticatedTemplate,
  useAccount,
} from "@azure/msal-react";
import {
  AuthenticationResult,
  EventType,
  InteractionStatus,
  InteractionType,
  RedirectRequest,
} from "@azure/msal-browser";

import Routing from "./pages/Routing";
import { b2cPolicies, loginRequest } from "./app/services/auth/authConfig";
import Footer from "./components/Footer";
import "focus-visible/dist/focus-visible";
import { useAppSelector } from "./app/state/hooks";
import AuthenticatedUserWrapper from "./features/AuthenticatedUserWrapper";
import PageLoading from "./components/PageLoading";
import Heap from "./features/Heap";
import SideNav from "./components/SideNav/SideNav";
import { useMatch } from "react-router-dom";
import { usePostGraphVerifyUserEmailMutation } from "./app/services/pim/api/graph";
import { PostUserEmailVerify } from "./app/services/pim/types/graphTypes";
import ConfirmEmail from "./components/ConfirmEmail/ConfirmEmail";

function ShowDialog({ isOpen, onClose, dialogRef }: any) {
  const cancelRef = React.useRef<any>(null);

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Edit Profile
            </AlertDialogHeader>

            <AlertDialogBody>
              Profile has been edited successfully. Please sign-in again.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

const App = () => {
  const emailVerificationDataInitialValues: PostUserEmailVerify = {
    message: "",
    redirect_url: "",
    is_success: false,
    previously_verified: false,
  };

  const sidnavBgColor = useColorModeValue("blackAlpha.200", "blackAlpha.400");
  const { isProfileEditSuccess } = useAppSelector((s) => s.user);
  const dialogRef = React.useRef(null);
  const match = useMatch("/verify-email/:token");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationResponse, setVerificationResponse] = useState<PostUserEmailVerify>(
    emailVerificationDataInitialValues
  );

  const instance = globalThis.msalInstance;

  const [postAsyncVeryUserEmail, resultVerifyUserEmail] = usePostGraphVerifyUserEmailMutation();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    (async () => {
      const callbackId = instance.addEventCallback(async (event: any) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
            if (event.interactionType === InteractionType.Redirect) {
              instance.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
            } else if (event.interactionType === InteractionType.Popup) {
              instance
                .loginPopup(b2cPolicies.authorities.forgotPassword as RedirectRequest)
                .catch((e: any) => {
                  return;
                });
            }
          }
        }

        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ) {
          if (event?.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            instance.setActiveAccount(account);

            //redirect the user to where they were before they logged in
            if (payload.state) window.location.href = payload.state;
            /**
             * We need to reject id tokens that were not issued with the default sign-in policy.
             * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (
              event.payload.authority ===
              b2cPolicies.authorities.forgotPassword.authority.toLowerCase()
            ) {
              window.alert(
                "Password has been reset successfully. \nPlease sign-in with your new password."
              );
              return instance.logout();
            }
          }
        }
      });

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    })();
  }, []);

  const confirmEmail = async (token: string | undefined) => {
    try {
      await postAsyncVeryUserEmail(token || "").then((resp: any) => {
        if (resp.data) {
          setIsLoading(false);
          const d: PostUserEmailVerify = resp.data;
          setVerificationResponse(d);
        } else {
          throw new Error();
        }
      });
    } catch (error) {
      setIsLoading(false);
      setVerificationResponse({
        message:
          "An error encountered while processing your request. Please try again later or contact admin.",
        redirect_url: "/",
        is_success: false,
        previously_verified: false,
      });
    }
  };

  useEffect(() => {
    if (match) {
      setIsLoading(true);
      confirmEmail(match.params.token);
    }
  }, [match]);

  return (
    <>
      {match ? (
        <ConfirmEmail isLoading={isLoading} data={verificationResponse} />
      ) : (
        <>
          {!match && !isLoading && (
            <>
              <Header />
              <Heap />
              <ShowDialog
                isOpen={isProfileEditSuccess}
                onClose={() => {
                  instance.logout();
                }}
                dialogRef={dialogRef}
              />
              <Flex direction="column" align="stretch" alignItems="center" minH="100vh">
                <Box flex={1} overflow="auto" mt="60px" p="0" w="100%">
                  <HStack alignItems="stretch" spacing={0}>
                    <Box flexGrow={1} bgColor={sidnavBgColor}>
                      <SideNav />
                    </Box>
                    <VStack spacing={0} w="100%" alignItems="stretch">
                      <Flex
                        p={7}
                        //bgColor="white"
                        flex={1}
                        // overflow="auto"
                        padding="2rem 2.25rem 2.25rem"
                        maxW="180ch"
                      >
                        <AuthenticatedUserWrapper>
                          <Routing />
                        </AuthenticatedUserWrapper>
                      </Flex>
                    </VStack>
                  </HStack>
                </Box>

                <Footer />
              </Flex>
              <UnauthenticatedTemplate>
                <UnAuthRedirect />
              </UnauthenticatedTemplate>
            </>
          )}
        </>
      )}
    </>
  );
};

const UnAuthRedirect = () => {
  const { inProgress } = useMsal();
  const instance = globalThis.msalInstance;
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
      });
    }
  }, []);
  return <PageLoading />;
};

export default App;
