import { pimApi } from "./index";
import { ChangePasswordParam, UpdateSignInAccessParam } from "../types";
import { GetGraphUser, PostGraphUserParam, PostUserEmailVerify } from "../types/graphTypes";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getGraphUserByEmail: builder.query<GetGraphUser, string>({
      query: (email) => `/graph/user/email/${email}`,
      providesTags: ["GraphUser"],
    }),

    postChangePassword: builder.mutation<void, ChangePasswordParam>({
      query: (params) => ({
        url: `/graph/user/changepassword`,
        method: "POST",
        body: params,
      }),
    }),

    postGraphUser: builder.mutation<void, PostGraphUserParam>({
      query: (params) => ({
        url: `/graph/user`,
        method: "POST",
        body: params,
      }),
    }),
    postGraphVerifyUserEmail: builder.mutation<PostUserEmailVerify, string>({
      query: (jwtToken) => ({
        url: `/graph/user/verify`,
        method: "POST",
        body: { encrypted_value: jwtToken },
      }),
    }),

    PutSignInAccess: builder.mutation<any, UpdateSignInAccessParam>({
      query: (params) => ({
        url: `/graph/user/signinaccess`,
        method: "PUT",
        body: params,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGraphUserByEmailQuery,
  usePostChangePasswordMutation,
  usePostGraphUserMutation,
  useLazyGetGraphUserByEmailQuery,
  usePutSignInAccessMutation,
  usePostGraphVerifyUserEmailMutation,
} = extendedApi;
