import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import counterReducer from "../../features/Counter/counterSlice";
import appReducer from "../slices/appSlice";
import { providerApi } from "../services/provider/api";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import { cmsApi } from "../services/cms/api";
import userReducer from "./../slices/userSlice";
import userRoleReducer from "./../slices/userRoleSlice";
import { pimApi } from "../services/pim/api";

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    //user: persistedReducer,
    user: userReducer,
    app: appReducer,
    counter: counterReducer,
    userRoles: userRoleReducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [pimApi.reducerPath]: pimApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(pimApi.middleware)
});

/*

*/

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
