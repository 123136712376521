import { Grid, GridItem, Heading, Stack } from "@chakra-ui/layout";
import { TabList, Tab, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { AppSSOUserDto, RefUserDto } from "@/app/services/pim/types";
import CustomTabs from "@/components/CustomTabs";
import PanelCard from "@/components/PanelCard";
import useRoles from "@/hooks/useRoles";

import UserApplicationList from "./Tabs/UserApplicationList";
import AddRefUserModal from "./AddRefUserModal";
import UserEventList from "./UserEventList";
import UserDetail from "./Tabs/UserDetail";
import UserRole from "./Tabs/UserRole";
import UserList from "./UserList";
import B2CInfo from "./Tabs/B2CInfo";

type Props = {};

const isSequelaeEmail = (email: string) => {
  return email.endsWith("@sequel.ae");
};

const User = (props: Props) => {
  const [selectedUser, setSelectedUser] = useState<RefUserDto | null>(null);
  const [newlyAddedUser, setNewlyAddedUser] = useState<RefUserDto | null>(null);
  const [showRoles, setShowRoles] = useState(false);
  const [selectedApp, setSelectedApp] = useState<AppSSOUserDto | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { hasRoles } = useRoles();
  const hasUserRoleRead = hasRoles([
    "UserRole.Read",
    "UserRole.Write",
    "SuperAdmin"
  ]);

  const onClickApp = (userApp: AppSSOUserDto | null) => {
    setSelectedApp(userApp);
  };

  const onClickUser = (user: RefUserDto | null) => {
    setSelectedUser(user);
  };

  const onSuccessAddRefUser = (user: RefUserDto | null) => {
    if (user) {
      setNewlyAddedUser(user);
    }
  };

  const userAppRoleTabs = [
    {
      index: 1,
      headerTitle: (
        <Heading as="h5" size="md" colorScheme="brand.main">
          Application
        </Heading>
      ),
      tabContent: selectedUser ? (
        <UserApplicationList
          selectedUser={selectedUser}
          selectedApp={selectedApp}
          onClickUser={onClickUser}
          onClickApp={onClickApp}
        />
      ) : (
        <></>
      )
    },
    ...(showRoles && hasUserRoleRead
      ? [
          {
            index: 2,
            headerTitle: (
              <Heading as="h5" size="md">
                Roles
              </Heading>
            ),
            tabContent: selectedUser ? <UserRole user={selectedUser} /> : <></>
          }
        ]
      : []),
    {
      index: 3,
      headerTitle: (
        <Heading as="h5" size="md">
          Details
        </Heading>
      ),
      tabContent: selectedUser ? <UserDetail user={selectedUser} /> : <></>
    },
    {
      index: 4,
      headerTitle: (
        <Heading as="h5" size="md">
          B2C Info
        </Heading>
      ),
      tabContent: selectedUser ? <B2CInfo email={selectedUser.email} /> : <></>
    }
  ];

  const UserAppTabHeader = () => (
    <>
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        flexGrow={1}
      >
        <TabList flexGrow={1}>
          {userAppRoleTabs.map((tab, index) => (
            <Tab key={index}>{tab.headerTitle}</Tab>
          ))}
        </TabList>
      </Tabs>
    </>
  );

  useEffect(() => {
    setTabIndex(0);
    setSelectedApp(null);

    setShowRoles(isSequelaeEmail(selectedUser?.email || ""));
  }, [selectedUser]);

  const clearSelected = () => {
    setSelectedApp(null);
    setSelectedUser(null);
  };

  return (
    <Grid templateColumns="450px 525px 450px" gap={3}>
      <GridItem>
        <PanelCard
          header={<AddRefUserModal onSuccess={onSuccessAddRefUser} />}
          fullHeight
        >
          <UserList
            onClickUser={onClickUser}
            selectedUser={selectedUser}
            onUserListChange={clearSelected}
            newlyAddedUser={newlyAddedUser}
          />
        </PanelCard>
      </GridItem>
      {selectedUser && (
        <GridItem>
          <PanelCard
            header={<UserAppTabHeader />}
            fullHeight
            onClose={() => {
              setSelectedUser(null);
              setSelectedApp(null);
            }}
          >
            <Stack>
              <CustomTabs
                tabs={userAppRoleTabs}
                tabIndex={tabIndex}
                hideHeader
                handleClose={() => {
                  onClickApp(null);
                }}
                onChange={(index) => setTabIndex(index)}
              />
            </Stack>
          </PanelCard>
        </GridItem>
      )}
      {selectedApp && tabIndex === 0 && (
        <GridItem>
          <PanelCard
            header={`Event`}
            fullHeight
            onClose={() => setSelectedApp(null)}
          >
            <UserEventList selectedApp={selectedApp} />
          </PanelCard>
        </GridItem>
      )}
    </Grid>
  );
};

export default User;
