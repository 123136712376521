import { useAccount, useIsAuthenticated } from "@azure/msal-react";
import { memo, useEffect } from "react";
import { getIdTokenClaims } from "../../app/services/auth/helper";

const Heap = memo(() => {
  const account = useAccount();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      const { emails } = getIdTokenClaims(account);
      if (emails) {
        (window as any).heap.identify(emails[0]);
      }
    }
  }, [isAuthenticated]);

  return <></>;
});

export default Heap;
