import { Heading, VStack } from "@chakra-ui/react";
import React from "react";

type Props = {};

const UnAuthorized = (props: Props) => {
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <VStack align="stretch" alignItems="center" mt="9vh">
        <Heading size="4xl">401</Heading>
        <Heading size="xl">Unauthorized Access</Heading>
      </VStack>
    </div>
  );
};

export default UnAuthorized;
