import { Row } from "react-table";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import { convertUtcToLocal } from "@/app/helpers/dateHelper";
import { useGetRefRoleListQuery } from "@/app/services/pim/api/refRole";
import CustomTable from "@/components/CustomTable";
import PanelCard from "@/components/PanelCard";
import AppAuth from "@/features/AppAuth";
import AddRoleModal from "./Modal";

import type { RefRoleDto } from "@/app/services/pim/types";
import useRoles from "@/hooks/useRoles";
import UnAuthorized from "@/pages/UnAuthorized";

const AdminRole = () => {
  const refRoleDetail = useGetRefRoleListQuery();
  const { hasRoles } = useRoles();
  const hasRead = hasRoles(["RefRole.Read", "SuperAdmin"]);

  const Header = [
    {
      Header: "Id",
      accessor: "ref_role_id"
    },
    {
      Header: "Name",
      accessor: "role_name"
    },
    {
      Header: "Description",
      accessor: "role_desc",
      styles: { whiteSpace: "initial", wordBreak: "break-word" }
    },
    {
      Header: "Created By",
      accessor: "row_created_by_user_name"
    },
    {
      Header: "Created Date Time",
      Cell: ({ row: { original } }: { row: Row<RefRoleDto> }) => {
        return <>{convertUtcToLocal(original.row_created_datetime_utc)}</>;
      }
    },
    {
      Header: "Modified By",
      accessor: "row_modified_by_user_name"
    },
    {
      Header: "Modified Date Time",
      Cell: ({ row: { original } }: { row: Row<RefRoleDto> }) => {
        return <>{convertUtcToLocal(original.row_modified_datetime_utc)}</>;
      }
    }
  ];

  return (
    <>
      {!hasRead ? (
        <UnAuthorized />
      ) : (
        <PanelCard
          w="full"
          header={
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                Roles
                <AppAuth requiredRole={["RefRole.Write", "SuperAdmin"]}>
                  <AddRoleModal
                    refreshList={refRoleDetail.refetch}
                    triggerElement={
                      <Button
                        ml={3}
                        leftIcon={<AddIcon />}
                        boxShadow="sm"
                        size="sm"
                      >
                        Add Role
                      </Button>
                    }
                  />
                </AppAuth>
              </div>
            </>
          }
        >
          <CustomTable
            variant="table"
            headers={Header}
            isLoading={refRoleDetail.isLoading}
            isFetching={refRoleDetail.isFetching}
            data={refRoleDetail.data ?? []}
            pageCount={1}
            pageSize={refRoleDetail.data?.length ?? 0}
            totalRecords={refRoleDetail.data?.length ?? 0}
            tableSort
            search={""}
            onPageChange={(index) => {}}
            onPageSizeChange={(size) => {}}
            onPageSearch={(search) => {}}
            onSort={() => {}}
            hidePagination
            showRecordCount
            localSearch
          />
        </PanelCard>
      )}
    </>
  );
};

export default AdminRole;
