/* eslint-disable react-hooks/exhaustive-deps */
import { useAccount } from "@azure/msal-react";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getIdTokenClaims } from "../../app/services/auth/helper";
import { useGetUserRoleListByEmailQuery } from "../../app/services/pim/api/userRole";
import { UserRoleDto } from "../../app/services/pim/types";
import { setCurrentUserRoles } from "../../app/slices/userRoleSlice";
import { useAppSelector } from "../../app/state/hooks";
import { AppAuthRequiredRole } from "../../app/types/appType";

interface IProps {
  children: ReactNode | ((isAuthorized: boolean) => React.ReactNode);
  requiredRole: AppAuthRequiredRole;
  onUnAuthorized?: () => void;
}

const AppAuth: FC<IProps> = ({ children, requiredRole, onUnAuthorized }) => {
  const dispatch = useDispatch();
  const { logonUser } = useAppSelector((s) => s.user);
  const [userUserRoles, setUserUserRoles] = useState<UserRoleDto[] | null>(
    null
  );

  const account = useAccount();

  const [email, setEmail] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isCheckingRole, setIsCheckingRole] = useState(true);
  const { data, isError, isSuccess, error, isLoading, isFetching } =
    useGetUserRoleListByEmailQuery(email, {
      skip: Boolean(userUserRoles) || !email
    });

  useEffect(() => {
    setIsCheckingRole(true);
  }, []);

  useEffect(() => {
    if (userUserRoles && logonUser && !logonUser?.disabled_flag) {
      const inRole =
        (userUserRoles.length && requiredRole.toLocaleString() === "*") ||
        userUserRoles.some((s) => requiredRole.includes(s.role_name as any));

      setIsAuthorized(inRole);
      setIsCheckingRole(false);
      !inRole && onUnAuthorized && onUnAuthorized();
    }
  }, [userUserRoles, data]);

  useEffect(() => {
    const { emails } = getIdTokenClaims(account);
    if (emails?.length) {
      setEmail(emails[0]);
    }
  }, [account]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsCheckingRole(true);
    }
  }, [data, isError, isSuccess, error, isLoading, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      setUserUserRoles(data);
      dispatch(setCurrentUserRoles(data));
    }
  }, [isSuccess, data]);

  if (typeof children === "function") {
    return <>{children(isAuthorized)}</>;
  } else {
    return <>{!isCheckingRole && isAuthorized && <>{children}</>}</>;
  }
};

export default AppAuth;
