import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@chakra-ui/button";
import { useFormik } from "formik";
import { Checkbox } from "@chakra-ui/checkbox";
import { Input } from "@chakra-ui/input";
import { VStack } from "@chakra-ui/layout";
import { Alert, AlertIcon, useDisclosure } from "@chakra-ui/react";
import { usePostRefDomainMutation } from "@/app/services/pim/api/refDomain";

import {
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/form-control";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/modal";

import type { IRefDomainFields } from "@/app/services/pim/types";

interface IProps {
  fields: IRefDomainFields;
  trigger: React.ReactNode;
  refreshList: () => void;
}

const FormSchema = Yup.object().shape({
  domain_name: Yup.string()
    .label("Role Name")
    .required()
    .max(100, "Text exceed the character limit of 100")
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed.")
});

const AddDomainModal = (props: IProps) => {
  const [postAsync, postStatus] = usePostRefDomainMutation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [alertMessage, setAlertMessage] = useState("");

  const onSubmit = (fields: IRefDomainFields) => {
    postAsync({ model: fields });
  };

  const onModalClose = () => {
    setAlertMessage("");
    resetForm();
    onClose();
  };

  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: FormSchema,
      onSubmit,
      initialValues: props.fields
    });

  const clearModal = useCallback(() => {
    resetForm();
    setAlertMessage("");
  }, [resetForm]);

  useEffect(() => {
    if (postStatus.isSuccess) {
      onClose();
    }
  }, [onClose, postStatus.isSuccess]);

  useEffect(() => {
    const { isSuccess, isError, isLoading, error } = postStatus;

    if (isSuccess) {
      resetForm();
      clearModal();
      onClose();
      props.refreshList();
    }

    if (isError) {
      const tempError = error as any;
      if (tempError.status === 409) {
        setAlertMessage("Domain name already exists.");
      } else {
        setAlertMessage(
          "There was an error processing your request, please try again later."
        );
      }
    }

    if (isLoading) {
      setAlertMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearModal, onClose, postStatus, props.refreshList, resetForm]);

  return (
    <>
      <div onClick={onOpen}> {props.trigger}</div>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Add Domain"} </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              <VStack align="stretch" spacing={3}>
                <FormControl
                  isRequired
                  isInvalid={!!errors.domain_name && touched.domain_name}
                >
                  <FormLabel htmlFor="domain_name">Domain Name</FormLabel>
                  <Input
                    id="domain_name"
                    placeholder="Enter Domain Name"
                    name="domain_name"
                    onChange={handleChange}
                    value={values.domain_name}
                  />
                  <FormErrorMessage>{errors.domain_name}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors.domain_desc && touched.domain_desc}
                >
                  <FormLabel htmlFor="domain_desc">Domain </FormLabel>
                  <Input
                    id="domain_desc"
                    placeholder="Enter Domain Description"
                    name="domain_desc"
                    onChange={handleChange}
                    value={values.domain_desc}
                  />
                  <FormErrorMessage>{errors.domain_name}</FormErrorMessage>
                </FormControl>

                <Checkbox
                  id="disabled_flag"
                  name="disabled_flag"
                  isChecked={Boolean(values.disabled_flag)}
                  onChange={handleChange}
                >
                  Disabled
                </Checkbox>
              </VStack>

              <VStack spacing={5} mt={1}>
                {alertMessage && (
                  <Alert status={postStatus.isSuccess ? "success" : "error"}>
                    <AlertIcon />
                    {alertMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onModalClose}
                mr={3}
                disabled={postStatus.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={postStatus.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDomainModal;
