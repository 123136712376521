import { pimApi } from "./index";
import {
  AddRefDemographicParam,
  RefDemographicDto,
  RefDemographicPageDto
} from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getRefDemographicList: builder.query<RefDemographicPageDto, string>({
      query: (searchString) => `/RefDemographic?${searchString}`,
      keepUnusedDataFor: 3,
      providesTags: ["RefDemographic"]
    }),
    getRefDemographicForAppAssignList: builder.query<
      Array<RefDemographicDto>,
      number
    >({
      query: (ref_app_id) => `/RefDemographic/forassign/refappid/${ref_app_id}`,
      keepUnusedDataFor: 3,
      providesTags: ["ForAssignRefDemographic"]
    }),
    postRefDemographic: builder.mutation<void, AddRefDemographicParam>({
      query: (params) => ({
        url: "/RefDemographic",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["RefDemographic"]
    })
  }),
  overrideExisting: false
});

export const {
  useGetRefDemographicListQuery,
  useGetRefDemographicForAppAssignListQuery,
  usePostRefDemographicMutation
} = extendedApi;
