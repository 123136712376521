import { HStack, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { FC } from "react";
import { CgClose } from "react-icons/cg";

export type TabModel = {
  index: number;
  headerTitle: JSX.Element | string;
  tabContent: JSX.Element;
};

interface IProps {
  tabs: TabModel[];
  tabIndex: number | undefined;
  closable?: boolean;
  hideHeader?: boolean;
  handleClose?: () => void;
  onChange?: (tabIndex: number) => void;
}

const CustomTabs: FC<IProps> = ({ tabs, closable, tabIndex, hideHeader, ...props }) => {
  return (
    <Tabs index={tabIndex} onChange={props.onChange}>
      <HStack>
        {!hideHeader && (
          <TabList flexGrow={1}>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab.headerTitle}</Tab>
            ))}
          </TabList>
        )}

        {closable && (
          <IconButton
            variant="link"
            colorScheme="gray"
            size="md"
            aria-label="Close"
            icon={<CgClose />}
            title="Close"
            onClick={props.handleClose}
          />
        )}
      </HStack>

      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel px={0} key={index}>
            {tab.tabContent}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CustomTabs;
