import { ComponentStyleConfig } from "@chakra-ui/react";
import brandColors from "../brandColors";

const Checkbox: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    control: {
      borderColor: brandColors.primary.whiteBlack[200],
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    // size: "",
    // variant: "",
    //colorScheme: "brand.main",
  },
};
export default Checkbox;
