import { Flex } from "@chakra-ui/layout";
import { FC } from "react";
import { GoCheck, GoX } from "react-icons/go";
import { BsFillEyeFill, BsPencilFill } from "react-icons/bs";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { Tooltip } from "@chakra-ui/tooltip";
import { MdInfo } from "react-icons/md";

import { useGetRefAppListQuery } from "../../../app/services/pim/api/refApp";
import { RefAppDto } from "../../../app/services/pim/types";
import CustomTable from "../../../components/CustomTable";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import AppAuth from "../../../features/AppAuth";
import TooltipLabelValue from "../../../components/TooltipLabelValue";
import Modal from "./Modal";
import { useGetRefDomainListQuery } from "@/app/services/pim/api/refDomain";

interface IProps {
  onAppClick: (refApp: RefAppDto) => void;
}

const AppList: FC<IProps> = ({ onAppClick }) => {
  const refAppResult = useGetRefAppListQuery();
  const refDomainResult = useGetRefDomainListQuery();
  const refAppDomains = refDomainResult.data?.data || [];

  const GetAppTableHeader = (onEyeIconClick: (refApp: RefAppDto) => void) => [
    {
      Header: "Action",
      style: { textAlign: "center" },
      Cell: ({ row: { original } }: { row: { original: RefAppDto } }) => {
        const {
          ref_app_id,
          ref_domain_id,
          app_name,
          web_url,
          transmit_flag,
          disabled_flag,
          app_alias
        } = original;

        return (
          <>
            <ButtonGroup spacing={2}>
              <Tooltip
                label={
                  <TooltipLabelValue
                    labelValueList={[
                      { label: "ID", value: original.ref_app_id },
                      { label: "App GUID", value: original.app_guid },
                      {
                        label: "Disabled",
                        value: original.disabled_flag ? "Yes" : "No"
                      },
                      {
                        label: "Disabled Date",
                        value: convertUtcToLocal(original.disabled_date)
                      }
                    ]}
                    auditFieldsObject={original}
                  />
                }
              >
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="View Details"
                  icon={<MdInfo />}
                />
              </Tooltip>

              <AppAuth requiredRole={["RefApp.Write"]}>
                <Modal
                  action="edit"
                  fields={{
                    app_name: app_name,
                    web_url,
                    transmit_flag,
                    disabled_flag,
                    app_alias,
                    ref_domain_id,
                    app_support_email: "",
                    app_relay_email: ""
                  }}
                  appId={ref_app_id}
                  trigger={
                    <Tooltip label="Edit">
                      <IconButton
                        minWidth={1}
                        variant="link"
                        aria-label="Edit App"
                        icon={<BsPencilFill />}
                      />
                    </Tooltip>
                  }
                  refAppDomains={refAppDomains}
                />
              </AppAuth>

              <Tooltip label="View App Demographic">
                <IconButton
                  variant="link"
                  aria-label="View App"
                  icon={<BsFillEyeFill />}
                  onClick={() => onEyeIconClick(original)}
                  minWidth={1}
                />
              </Tooltip>
            </ButtonGroup>
          </>
        );
      }
    },
    {
      Header: "App Name",
      accessor: "app_name"
    },
    {
      Header: "App Alias",
      accessor: "app_alias"
    },
    {
      Header: "App URL",
      accessor: "web_url"
    },
    {
      Header: "Transmit",
      style: { textAlign: "center" },
      accessor: "transmit_flag",
      Cell: ({ row: { original } }: { row: { original: RefAppDto } }) => {
        return (
          <Flex justifyContent="center">
            {original.transmit_flag ? <GoCheck /> : <GoX />}
          </Flex>
        );
      }
    },
    {
      Header: "App Domain",
      accessor: "domain_name"
    }
  ];

  const onEyeIconClick = (refApp: RefAppDto) => {
    onAppClick(refApp);
  };

  let sortedData = [...(refAppResult.data || [])];

  if (sortedData.length > 0) {
    sortedData.sort((a, b) => {
      const appNameA = a.app_name.toLowerCase();
      const appNameB = b.app_name.toLowerCase();

      if (appNameA < appNameB) {
        return -1;
      }
      if (appNameA > appNameB) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <CustomTable
      variant="table"
      headers={GetAppTableHeader(onEyeIconClick)}
      isLoading={refAppResult.isLoading}
      isFetching={refAppResult.isFetching}
      data={sortedData}
      pageCount={0}
      pageSize={refAppResult.data?.length || 0}
      totalRecords={refAppResult.data?.length || 0}
      search=""
      hidePagination
      onSort={() => {}}
      onPageChange={(index) => {}}
      onPageSizeChange={(size) => {}}
      onPageSearch={(search) => {}}
      tableSort
      showRecordCount
      localSearch
    />
  );
};

export default AppList;
