import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
  useDisclosure
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { usePostRefRoleMutation } from "@/app/services/pim/api/refRole";
import type { AddRefRoleParams } from "@/app/services/pim/types";

const FormSchema = Yup.object().shape({
  role_name: Yup.string()
    .label("Role Name")
    .required()
    .max(100, "Text exceed the character limit of 100")
    .matches(/^[a-zA-Z0-9._]*$/, "Special characters are not allowed."),
  role_desc: Yup.string()
    .label("Role Description")
    .required()
    .max(2000, "Text exceed the character limit of 2000")
});

type Props = {
  triggerElement: JSX.Element;
  refreshList: () => void;
};

const AddRoleModal = ({ triggerElement, refreshList }: Props) => {
  const [alertMessage, setAlertMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postAsync, postStatus] = usePostRefRoleMutation();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    resetForm,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: {
      role_name: "",
      role_desc: ""
    },
    onSubmit: (fields: AddRefRoleParams) => {
      postAsync({ model: fields });
    }
  });

  const clearModal = useCallback(() => {
    resetForm();
    setAlertMessage("");
  }, [resetForm]);

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [clearModal, isOpen]);

  useEffect(() => {
    const { isSuccess, isError, isLoading, error } = postStatus;

    if (isSuccess) {
      resetForm();
      refreshList();
      clearModal();
      onClose();
    }

    if (isError) {
      const tempError = error as any;
      if (tempError.status === 409) {
        setAlertMessage("Role name already exists.");
      } else {
        setAlertMessage(
          "There was an error processing your request, please try again later."
        );
      }
    }

    if (isLoading) {
      setAlertMessage("");
    }
  }, [clearModal, onClose, postStatus, refreshList, resetForm]);

  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        {triggerElement}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size="2xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Role</ModalHeader>
          <ModalCloseButton isDisabled={postStatus.isLoading} />
          <>
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <VStack>
                  <FormControl
                    isInvalid={!!errors.role_name && touched.role_name}
                  >
                    <Input
                      id="role_name"
                      placeholder="Enter Name"
                      name="role_name"
                      onChange={handleChange}
                      value={values.role_name}
                      maxLength={100}
                      onBlur={(e) =>
                        setFieldValue(
                          "role_name",
                          e.target.value.replaceAll(" ", "")
                        )
                      }
                    />
                    <FormErrorMessage>{errors.role_name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors.role_desc && touched.role_desc}
                  >
                    <Textarea
                      id="role_desc"
                      placeholder="Enter Description"
                      name="role_desc"
                      onChange={handleChange}
                      value={values.role_desc}
                      maxLength={2000}
                    />
                    <FormErrorMessage>{errors.role_desc}</FormErrorMessage>
                  </FormControl>
                </VStack>

                <VStack spacing={5} mt={1}>
                  {alertMessage && (
                    <Alert status={postStatus.isSuccess ? "success" : "error"}>
                      <AlertIcon />
                      {alertMessage}
                    </Alert>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="outline"
                  onClick={onClose}
                  mr={3}
                  ml="auto"
                  isDisabled={postStatus.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.main"
                  isLoading={postStatus.isLoading}
                  isDisabled={alertMessage !== "" && postStatus.isSuccess}
                >
                  Add
                </Button>
              </ModalFooter>
            </form>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddRoleModal;
