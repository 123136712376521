import { FormControl, Switch, Text, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";

interface IProps {
  isChecked: boolean;
  onChange: () => void;
}

const GridViewSwitch: FC<IProps> = ({ isChecked, onChange }) => {
  const textColor = useColorModeValue("inherit", "whiteAlpha.600");
  return (
    <FormControl display="flex" alignItems="center">
      <Switch
        id="gridViewSwitch"
        mr={2}
        size="sm"
        isChecked={isChecked}
        onChange={onChange}
        //colorScheme="brand.main"
      />
      <Text color={textColor} as="small" casing="uppercase">
        Grid View
      </Text>
    </FormControl>
  );
};

export default GridViewSwitch;
