import React, { FC, ReactNode } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { AppAuthRequiredRole } from "../app/types/appType";
import AppAuth from "../features/AppAuth";
import Apps from "./Admin/Apps";
import Claims from "./Claims";
import Demographic from "./Admin/Demographic";
import Profile from "./Profile";
import ChangePassword from "./Profile/ChangePassword";
import UnAuthorized from "./UnAuthorized";
import User from "./User";
import UnverifiedEmails from "./UnverifiedEmails";
import Admin from "./Admin";
import AdminRole from "./Admin/Roles";
import Domains from "./Admin/Domains";

type Props = {};

const Routing = (props: Props) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              Component={User}
              requiredRole={["User.Read", "User.Write"]}
            />
          }
        />
        <Route
          path="/unverified-emails"
          element={
            <ProtectedRoute
              Component={UnverifiedEmails}
              requiredRole={["User.Read", "User.Write"]}
            />
          }
        />

        <Route
          path="/admin"
          element={
            <ProtectedRoute
              Component={Admin}
              requiredRole={["User.Read", "User.Write"]}
            />
          }
        >
          <Route index element={<Navigate to="apps" replace />} />
          <Route
            path="apps"
            element={
              <ProtectedRoute
                Component={Apps}
                requiredRole={["RefApp.Read", "RefApp.Write"]}
              />
            }
          />

          <Route
            path="demographics"
            element={
              <ProtectedRoute
                Component={Demographic}
                requiredRole={["RefDemographic.Read", "RefDemographic.Write"]}
              />
            }
          />

          <Route
            path="domains"
            element={
              <ProtectedRoute
                Component={Domains}
                requiredRole={["RefDomain.Read", "RefDomain.Write"]}
              />
            }
          />

          <Route
            path="roles"
            element={
              <ProtectedRoute
                Component={AdminRole}
                requiredRole={["RefRole.Read", "RefRole.Write", "SuperAdmin"]}
              />
            }
          />
        </Route>

        {/* Temporarily hide the consent > questions section */}
        {/* <Route
          path="/consent"
          element={
            <ProtectedRoute
              Component={Admin}
              requiredRole={["User.Read", "User.Write"]}
            />
          }
        >
          <Route index element={<Navigate to="questions" replace />} />
          <Route
            path="questions"
            element={
              <ProtectedRoute
                Component={Questions}
                requiredRole={["RefApp.Read", "RefApp.Write"]}
              />
            }
          />
        </Route> */}

        {/* <Route
          path="/questions/:questionId/answers"
          element={
            <ProtectedRoute
              Component={Answers}
              requiredRole={[
                "Consent.RefQuestion.Read",
                "Consent.RefQuestion.Write"
              ]}
            />
          }
        /> */}

        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/password" element={<ChangePassword />} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />

        <Route path="*" element={<strong>Page not found</strong>} />
      </Routes>
    </>
  );
};

export default Routing;

interface IProtectedRouteProps {
  children?: ReactNode;
  Component: React.ComponentType;
  requiredRole: AppAuthRequiredRole;
}
const ProtectedRoute: FC<IProtectedRouteProps> = ({
  Component,
  requiredRole
}): any => {
  const navigate = useNavigate();

  const onUnAuthorized = () => {
    navigate("/unauthorized");
  };
  return (
    <AppAuth requiredRole={requiredRole} onUnAuthorized={onUnAuthorized}>
      <Component />
    </AppAuth>
  );
};
