import { IconButton } from "@chakra-ui/button";
import { Flex, HStack, VStack } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { FC } from "react";
import { GoCheck, GoX } from "react-icons/go";
import { MdInfo } from "react-icons/md";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import { useGetAppDemographicByRefAppIdQuery } from "../../../app/services/pim/api/appDemographic";
import { AppDemographicDto, RefAppDto } from "../../../app/services/pim/types";
import CustomTable from "../../../components/CustomTable";
import TooltipLabelValue from "../../../components/TooltipLabelValue";
import AppAuth from "../../../features/AppAuth";
import AssignDemographicModal from "./AssignDemographicModal";

interface IProps {
  refApp: RefAppDto;
}

const AppDemographicTableHeader = [
  {
    Header: "Action",
    style: { textAlign: "center" },
    accessor: "app_demographic_id",
    Cell: ({ row: { original } }: { row: { original: AppDemographicDto } }) => {
      return (
        <>
          <Tooltip
            label={
              <TooltipLabelValue
                labelValueList={[
                  { label: "ID", value: original.app_demographic_id },
                  {
                    label: "Disabled",
                    value: original.disabled_flag ? "Yes" : "No"
                  },
                  {
                    label: "Disabled Date",
                    value: convertUtcToLocal(original.disabled_datetime_utc)
                  }
                ]}
                auditFieldsObject={original}
              />
            }
          >
            <IconButton
              minWidth={1}
              variant="link"
              aria-label="View Details"
              icon={<MdInfo />}
            />
          </Tooltip>
        </>
      );
    }
  },
  {
    Header: "Name",
    accessor: "demographic_name"
  },
  {
    Header: "Description",
    accessor: "demographic_desc"
  },
  {
    Header: "Type",
    accessor: "data_type"
  },
  {
    Header: "Length",
    accessor: "data_length"
  },
  {
    Header: "Format",
    accessor: "data_format"
  },
  {
    Header: "Required",
    accessor: "required_field_flag",
    Cell: ({ row: { original } }: { row: { original: AppDemographicDto } }) => {
      return (
        <Flex justifyContent="center">
          {original.required_field_flag ? <GoCheck /> : <GoX />}
        </Flex>
      );
    }
  }
];

const AppDemographicList: FC<IProps> = ({ refApp }) => {
  const appDemogResult = useGetAppDemographicByRefAppIdQuery(
    refApp.ref_app_id,
    {
      skip: !refApp.ref_app_id
    }
  );
  return (
    <VStack align="stretch">
      <HStack justifyContent="flex-end">
        <AppAuth requiredRole={["RefDemographic.Write"]}>
          <AssignDemographicModal refApp={refApp} />
        </AppAuth>
      </HStack>
      <CustomTable
        variant="table"
        headers={AppDemographicTableHeader}
        isLoading={appDemogResult.isLoading}
        isFetching={appDemogResult.isFetching}
        data={appDemogResult.data || []}
        pageCount={0}
        pageSize={3}
        totalRecords={9}
        search=""
        onPageChange={(index) => {
          //setPageNumber(index);
        }}
        onPageSizeChange={(size) => {
          //setPageSize(size);
        }}
        onPageSearch={(search) => {
          // setPageNumber(1);
          // setSearch(search);
        }}
        onSort={() => {}}
        hidePagination={true}
        rowDisabledOnTrue={(row) => Boolean(row.disabled_flag)}
      />
    </VStack>
  );
};

export default AppDemographicList;
