import * as Yup from "yup";
import { FC, useEffect } from "react";
import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { Input } from "@chakra-ui/input";
import { useFormik } from "formik";
import { VStack } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";

import {
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/form-control";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/modal";

import {
  usePostRefAppMutation,
  usePutRefAppMutation
} from "../../../app/services/pim/api/refApp";

import type { RefAppDto, RefDomainDto } from "../../../app/services/pim/types";

type Fields = Pick<
  RefAppDto,
  | "app_name"
  | "app_alias"
  | "web_url"
  | "disabled_flag"
  | "transmit_flag"
  | "app_support_email"
  | "app_relay_email"
> & { ref_domain_id?: number };

interface IProps {
  action: "add" | "edit";
  appId?: number;
  fields: Fields;
  trigger: React.ReactNode;
  refAppDomains: RefDomainDto[];
}

const FormSchema = Yup.object().shape({
  app_name: Yup.string().label("App Name").required(),
  ref_domain_id: Yup.number().label("Domain").required()
});

const AddEditAppModal: FC<IProps> = (props) => {
  const [putAsync, putStatus] = usePutRefAppMutation();
  const [postAsync, postStatus] = usePostRefAppMutation();
  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (putStatus.isSuccess || postStatus.isSuccess) {
      onClose();
    }
  }, [putStatus, postStatus, onClose]);

  const onSubmit = async (fields: Fields) => {
    if (fields.ref_domain_id) {
      fields.ref_domain_id = Number(fields.ref_domain_id);
    }

    if (props.action === "edit" && props.appId) {
      await putAsync({ ref_app_id: props.appId, model: fields });
    }

    if (props.action === "add") {
      await postAsync({ model: fields });
    }
  };

  const onModalClose = () => {
    resetForm();
    onClose();
  };

  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: FormSchema,
      onSubmit,
      initialValues: props.fields
    });

  return (
    <>
      <div onClick={onOpen}> {props.trigger}</div>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {props.action === "edit"
              ? `Update ${props.fields.app_alias.toUpperCase()}`
              : "Add App"}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              <VStack align="stretch" spacing={3}>
                <FormControl
                  isRequired
                  isInvalid={!!errors.ref_domain_id && touched.ref_domain_id}
                >
                  <FormLabel htmlFor="ref_domain_id">Select Domain</FormLabel>
                  <Select
                    id="ref_domain_id"
                    placeholder="Select Domain"
                    name="ref_domain_id"
                    onChange={handleChange}
                    value={values.ref_domain_id}
                  >
                    {props.refAppDomains.map((domain) => (
                      <option
                        key={domain.ref_domain_id}
                        value={domain.ref_domain_id}
                      >
                        {domain.domain_name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.ref_domain_id}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!errors.app_name && touched.app_name}
                >
                  <FormLabel htmlFor="app_name">App Name</FormLabel>
                  <Input
                    id="app_name"
                    placeholder="Enter App Name"
                    name="app_name"
                    onChange={handleChange}
                    value={values.app_name}
                  />
                  <FormErrorMessage>{errors.app_name}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.web_url && touched.web_url}>
                  <FormLabel htmlFor="web_url">App Url</FormLabel>
                  <Input
                    id="web_url"
                    placeholder="Enter App URL"
                    name="web_url"
                    onChange={handleChange}
                    value={values.web_url}
                  />
                  <FormErrorMessage>{errors.app_name}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired={props.action === "add"}
                  isInvalid={!!errors.app_alias && touched.app_alias}
                >
                  <FormLabel htmlFor="app_alias">App Alias</FormLabel>
                  <Input
                    id="app_alias"
                    placeholder="Enter App Alias"
                    name="app_alias"
                    onChange={handleChange}
                    value={values.app_alias}
                    isDisabled={props.action === "edit"}
                  />
                  <FormErrorMessage>{errors.app_name}</FormErrorMessage>
                </FormControl>

                <Checkbox
                  id="transmit_flag"
                  name="transmit_flag"
                  isChecked={Boolean(values.transmit_flag)}
                  onChange={handleChange}
                >
                  Transmit
                </Checkbox>
                <Checkbox
                  id="disabled_flag"
                  name="disabled_flag"
                  isChecked={Boolean(values.disabled_flag)}
                  onChange={handleChange}
                >
                  Disabled
                </Checkbox>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onModalClose}
                mr={3}
                disabled={putStatus.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={putStatus.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEditAppModal;
