import { FC } from "react";
import { Flex, Icon, Link, Text, HStack, VStack } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavItemModel } from "./navItems";
import AppAuth from "../../features/AppAuth";

interface IProps {
  item: NavItemModel;
}

const NavItem: FC<IProps> = ({ item }) => {
  const location = useLocation();
  const isSelected = (href: string, strict?: boolean) => {
    if (strict) {
      return location.pathname === href;
    }
    return location.pathname === href || location.pathname.indexOf(href) === 0;
  };

  return (
    <VStack
      className={`navItem ${isSelected(item.href, item.strict) ? "selected" : ""}`}
      spacing={0}
    >
      <Link
        py={3}
        px={3}
        as={RouterLink}
        to={item.href}
        w="100%"
        borderRadius={4}
        fontWeight={isSelected(item.href, item.strict) ? "medium" : "normal"}
        whiteSpace="nowrap"
      >
        <Flex align="stretch" alignItems="center">
          <Icon
            as={item.icon} //color="sideNavMenu.iconColor"
            fontSize={18}
          />
          <Text ml={4}>{item.text}</Text>
        </Flex>
      </Link>
      <VStack
        align="stretch"
        hidden={!isSelected(item.href, item.strict)}
        overflowX="hidden"
        w="full"
        className="subNav"
      >
        {(item.subNav || []).map((sm, ii) =>
          sm.requiredRole ? (
            <AppAuth key={sm.text} requiredRole={sm.requiredRole}>
              <SubNav sm={sm} ii={ii} isSelected={isSelected} />
            </AppAuth>
          ) : (
            <SubNav key={sm.text} sm={sm} ii={ii} isSelected={isSelected} />
          )
        )}
      </VStack>
    </VStack>
  );
};

interface SubNavProps {
  sm: NavItemModel;
  ii: number;
  isSelected: (href: string, strict?: boolean) => boolean;
}
const SubNav = ({ sm, ii, isSelected }: SubNavProps) => {
  return (
    <Link
      key={ii}
      py={2}
      px={3}
      // pr={9}
      as={RouterLink}
      to={sm.href}
      w="100%"
      // whiteSpace="nowrap"
      borderColor="transparent"
      borderLeftWidth="3px"
      className={`${isSelected(sm.href, sm.strict) ? "selected" : ""}`}
    >
      <HStack pl={8} spacing={4} alignItems="center">
        <Icon as={sm.icon} fontSize={16} />
        <Text>{sm.text}</Text>
      </HStack>
    </Link>
  );
};

export default NavItem;
