import { AppSSOUserDto } from "../types";
import { pimApi } from "./index";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppSSOUserByRefUserId: builder.query<Array<AppSSOUserDto>, number>({
      query: (ref_user_id) => `/AppSSOUser/userid/${ref_user_id}`,
      keepUnusedDataFor: 3,
      providesTags: ["AppSSOUser"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAppSSOUserByRefUserIdQuery,
  useLazyGetAppSSOUserByRefUserIdQuery,
} = extendedApi;
