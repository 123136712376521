import { pimApi } from "./index";
import type { AddRefRoleParams, RefRoleDto } from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    postRefRole: builder.mutation<void, { model: AddRefRoleParams }>({
      query: (params) => ({
        url: `/refRole`,
        method: "POST",
        body: params.model
      })
    }),
    getRefRoleList: builder.query<RefRoleDto[], void>({
      query: () => `/refRole/list`
    })
  }),
  overrideExisting: false
});

export const { useGetRefRoleListQuery, usePostRefRoleMutation } = extendedApi;
