import { useAccount } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { useGetUserRoleListByEmailQuery } from "@/app/services/pim/api/userRole";
import { setCurrentUserRoles } from "@/app/slices/userRoleSlice";
import { getIdTokenClaims } from "@/app/services/auth/helper";
import { useAppSelector } from "@/app/state/hooks";

const useRoles = () => {
  const { currentUserRoles } = useAppSelector((s) => s.userRoles);
  const dispatch = useDispatch();
  const account = useAccount();

  const {
    emails: [email]
  } = getIdTokenClaims(account);

  const { data, isSuccess } = useGetUserRoleListByEmailQuery(email, {
    skip: !email
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCurrentUserRoles(data));
    }
  }, [isSuccess, data, dispatch]);

  const hasRoles = (roles: string[]) => {
    if (!roles) return true;
    if (!currentUserRoles) return false;

    return currentUserRoles.some((userRole) =>
      roles.includes(userRole.role_name)
    );
  };

  return { currentUserRoles, hasRoles };
};

export default useRoles;
