import {
  IconButton,
  useBoolean,
  ButtonGroup,
  Tooltip,
  VStack,
  Avatar,
  Text
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useGetUserListQuery } from "../../app/services/pim/api/user";
import CustomTable from "../../components/CustomTable";
import { RefUserDto } from "../../app/services/pim/types";
import { BsFillEyeFill, BsPencilFill } from "react-icons/bs";
import { MdInfo } from "react-icons/md";
import { useDebounce } from "../../app/helpers/stringHelper";
import EditUserDemographicModal from "../Profile/EditUserDemographicModal";
import EditUserSignInAccessModal from "./EditUserSignInAccessModal";
import GridViewSwitch from "../../components/GridViewSwitch";
import { convertUtcToLocal } from "../../app/helpers/dateHelper";

type Props = {
  onClickUser: (user: RefUserDto | null) => void;
  selectedUser: RefUserDto | null;
  onUserListChange?: () => void;
  newlyAddedUser: RefUserDto | null;
};

const accountCreatedStyle = {
  color: "#595959a6",
  fontSize: 13,
  fontWeight: "normal"
};

const UserList: FC<Props> = ({
  selectedUser,
  onClickUser,
  onUserListChange,
  newlyAddedUser
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [searchDebounce, setSearchDebounce] = useState("");
  const [gridViewFlag, setGridViewFlag] = useBoolean(true);
  const [tableData, setTableData] = useState<RefUserDto[] | null>(null);

  const debouncedSearch = useDebounce(search, 400);

  const { data, isLoading, isFetching, refetch } = useGetUserListQuery(
    new URLSearchParams({
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      q: searchDebounce
    }).toString(),
    { refetchOnMountOrArgChange: true }
  );

  const usersListData = data?.data
    ? data?.data.map((item) => {
        return {
          ...item,
          createdDate: dayjs(item.row_created_datetime_utc).format(
            "YYYY-MM-DD HH:mm A"
          )
        };
      })
    : null;

  const TableHeader = [
    {
      Header: "Action",
      isAction: true,
      style: { display: "flex", justifyContent: "center" },
      Cell: ({ row: { original } }: { row: { original: RefUserDto } }) => {
        const fullName = `${original.first_name} ${original.last_name}`;
        const avatarUrl = `${process.env.REACT_APP_CONTAINER_URL}/${process.env.REACT_APP_CONTAINER_NAME}/${original.email}.jpeg`;
        return (
          <VStack alignItems="end">
            <ButtonGroup spacing={1}>
              <Tooltip label={`ID : ${original.ref_user_id}`}>
                <IconButton
                  minWidth={1}
                  variant="link"
                  aria-label="View Details"
                  icon={<MdInfo />}
                />
              </Tooltip>

              <EditUserDemographicModal
                headerTitle="Edit User"
                title=""
                refUserId={original.ref_user_id || 0}
                onSuccess={() => refetch()}
              >
                <Tooltip label="Edit User">
                  <IconButton
                    minWidth={1}
                    variant="link"
                    aria-label="Edit User"
                    icon={<BsPencilFill />}
                  />
                </Tooltip>
              </EditUserDemographicModal>

              <Tooltip label="View App">
                <IconButton
                  variant="link"
                  aria-label="View App"
                  icon={<BsFillEyeFill />}
                  onClick={() => onSelectUser(original)}
                  minWidth={1}
                />
              </Tooltip>
              <EditUserSignInAccessModal
                user={original}
                onSuccess={onSuccessBlockUnblock}
              />
            </ButtonGroup>
            <Avatar
              name={`${fullName.trim() || original.email}`}
              src={avatarUrl}
              size="sm"
              bg="gray.300"
              //color="gray.800"
            />
          </VStack>
        );
      }
    },
    {
      Header: "First Name",
      accessor: "first_name"
    },
    {
      Header: "Last Name",
      accessor: "last_name"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Account Created",
      accessor: "row_created_datetime_utc",
      styles: { ...accountCreatedStyle },
      Cell: ({ row: { original } }: { row: { original: RefUserDto } }) => {
        return (
          <Text style={{ ...accountCreatedStyle }}>
            {convertUtcToLocal(original.row_created_datetime_utc)}
          </Text>
        );
      }
    }
  ];

  const onSelectUser = (data: RefUserDto) => {
    onClickUser(data);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setPageSize(() => (gridViewFlag ? 5 : 20));
  }, [gridViewFlag]);

  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    setTableData(usersListData);
  }, [data]);

  useEffect(() => {
    if (data && data.data) {
      //for manual select of user
      let newSelectedUser = data.data.find(
        (f) => f.ref_user_id === selectedUser?.ref_user_id
      );

      //auto select newly added user
      if (newlyAddedUser && !isFetching) {
        newSelectedUser = data.data.find(
          (f) => f.ref_user_id === newlyAddedUser?.ref_user_id
        );
      }

      if (newSelectedUser) {
        onClickUser(newSelectedUser);
      }
    }
    !newlyAddedUser && onUserListChange && onUserListChange();
  }, [data]);

  useEffect(() => {
    if (newlyAddedUser) {
      setSearch(newlyAddedUser.email);
    }
  }, [newlyAddedUser]);

  const onSuccessBlockUnblock = (updatedUser: RefUserDto) => {
    if (tableData) {
      setTableData(
        (users) =>
          users &&
          users.map((user) => {
            if (user.ref_user_id === updatedUser.ref_user_id) {
              return {
                ...user,
                block_signin_flag: updatedUser.block_signin_flag
              };
            }
            return user;
          })
      );
    }
  };

  return (
    <>
      {tableData && (
        <>
          <GridViewSwitch
            isChecked={gridViewFlag}
            onChange={setGridViewFlag.toggle}
          />
          <CustomTable
            variant={gridViewFlag ? "grid" : "table"}
            isLoading={isLoading}
            isFetching={isFetching}
            data={tableData ?? []}
            pageCount={data?.total_pages || 0}
            pageSize={data?.page_size || pageSize}
            totalRecords={data?.total_records || 0}
            headers={TableHeader}
            search={search}
            onPageChange={(index) => {
              setPageNumber(index);
            }}
            onPageSizeChange={(size) => {
              setPageSize(size);
            }}
            onPageSearch={(search) => {
              setPageNumber(1);
              setSearch(search);
            }}
            onSort={() => {}}
            rowDisabledOnTrue={(row) => Boolean(row.disabled_flag)}
            rowActiveOnTrue={(row) =>
              row.ref_user_id === selectedUser?.ref_user_id
            }
          />
        </>
      )}
    </>
  );
};

export default UserList;
