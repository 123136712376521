import { pimApi } from "./index";
import {
  GetUserListResponse,
  RefUserDto,
  //UserApplicationDto,
  UserEventDto,
  GetUserEventParam,
  PostUserApplicationDto,
  GetUnverifiedUserListResponse,
} from "../types";
import { string } from "yup";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query<RefUserDto, number>({
      query: (refUserId) => `/user/${refUserId}`,
      providesTags: ["User"],
    }),
    getUserByEmail: builder.query<RefUserDto, string>({
      query: (email) => `/user/email/${email}`,
      providesTags: ["User"],
    }),
    getUserList: builder.query<GetUserListResponse, string>({
      query: (searchString) => `/user?${searchString}`,
      providesTags: ["UserList"],
    }),
    // getUserApplicationList: builder.query<Array<UserApplicationDto>, number>({
    //   query: (refUserId) => `/user/app/refuserid/${refUserId}`,
    //   providesTags: ["User", "UserApp"],
    // }),
    getUserEventList: builder.query<Array<UserEventDto>, GetUserEventParam>({
      query: (param) => `/UserEvent/refuserid/${param.ref_user_id}/refappid/${param.ref_app_id}`,
      providesTags: ["UserEvent"],
    }),
    getUnverifiedUserList: builder.query<GetUnverifiedUserListResponse, string>({
      query: (searchString) => `/user/unverified/emails?${searchString}`,
      providesTags: ["UnverifiedUserList"]
    }),

    postUserApplication: builder.mutation<void, PostUserApplicationDto>({
      query: (params) => ({
        url: "/user",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["AppSSOUser", "UserEvent"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserByIdQuery,
  useGetUserByEmailQuery,
  useGetUserListQuery,
  useGetUserEventListQuery,
  useGetUnverifiedUserListQuery,
  usePostUserApplicationMutation,
  useLazyGetUserByEmailQuery,
} = extendedApi;
