import { SearchIcon } from "@chakra-ui/icons";
import {
  VStack,
  TableContainer,
  Stack,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Text,
  chakra,
  Icon,
  Tbody,
  Td,
  HStack,
  IconButton,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Box,
  List,
  ListItem,
  theme,
  useColorModeValue
} from "@chakra-ui/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  CgChevronDoubleLeft,
  CgChevronLeft,
  CgChevronRight,
  CgChevronDoubleRight
} from "react-icons/cg";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  SortingRule,
  RowPropGetter,
  HeaderGroup,
  useGlobalFilter
} from "react-table";
import { appColors } from "../../app/theme";

export interface IProps<T> {
  data: T[];
  pageCount: number;
  pageSize: number;
  totalRecords?: number;
  isLoading: boolean;
  isFetching: boolean;
  headers: Column<any>[];
  hiddenColumns?: string[];
  tableSort?: boolean;
  search: string;
  hidePagination?: boolean;
  showRecordCount?: boolean;
  rowDisabledOnTrue?: (row: T) => boolean;
  rowActiveOnTrue?: (row: T) => boolean;
  activeRow?: { property: string; value: any };
  variant: "table" | "grid";
  onPageChange: (pageIndex: number) => void;
  onPageSizeChange?: (size: number) => void;
  onPageSearch: (search: string) => void;
  onSort: (sort: SortingRule<object>[]) => void;
  getRowProps?: (row: any) => RowPropGetter<object> | undefined;
  localSearch?: boolean;
}

function CustomTable<T>(props: IProps<T>) {
  const columns = useMemo<Column<any>[]>(() => props.headers, [props.headers]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [search, setSearch] = useState("");

  const hoverRowBg = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  const activeRowBg = useColorModeValue(
    "var(--chakra-colors-blackAlpha-100)",
    "var(--chakra-colors-whiteAlpha-100)"
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 18,
        hiddenColumns: props.hiddenColumns || []
      },
      pageCount: tablePageCount,
      manualPagination: true,
      autoResetPage: false, //prevent re-executing hook with initialState again
      manualSortBy: false,
      autoResetSortBy: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const keyUpHandler = (event: any) => {
    event.preventDefault();
    gotoPage(0);
    props.onPageSearch(search);
  };

  const { globalFilter } = state;

  useEffect(() => {
    setSearch(props.search);
  }, [props.search]);

  useEffect(() => {
    setTableData(props.data);
    setTablePageCount(props.pageCount);
  }, [props]);

  useEffect(() => {
    const index = pageIndex === 0 ? 1 : pageIndex + 1;
    props.onPageChange(index);
    !props.isFetching && props.onSort(sortBy);
  }, [pageIndex, pageSize, props, sortBy]);

  const applySortIcon = (column: HeaderGroup<object>) => {
    return column.isSortedDesc ? (
      <Icon as={GoTriangleDown} aria-label="sorted descending" />
    ) : (
      <Icon as={GoTriangleUp} aria-label="sorted ascending" />
    );
  };

  const tableView = (
    <TableContainer>
      {props.isLoading || props.isFetching ? (
        <Stack mt={1}>
          {[...Array(props.pageSize)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Table variant="simple" size="sm" mt={1} {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      let columnStyle = {
                        ...(column as any).style,
                        cursor: "pointer",
                        userSelect: "none"
                      };
                      return (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={columnStyle}
                        >
                          {column.render("Header")}
                          {props.tableSort && (
                            <chakra.span pl="3">
                              {column.isSorted ? applySortIcon(column) : null}
                            </chakra.span>
                          )}
                        </Th>
                      );
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                const rowProps = {
                  ...row.getRowProps(
                    props.getRowProps ? props.getRowProps(row) : undefined
                  )
                };

                const rowStyle = rowProps.style || {};
                if (
                  props.rowDisabledOnTrue &&
                  props.rowDisabledOnTrue(row.original as unknown as T)
                ) {
                  rowStyle.color = appColors.inactive;
                }

                if (
                  props.rowActiveOnTrue &&
                  props.rowActiveOnTrue(row.original as unknown as T)
                ) {
                  rowStyle.backgroundColor = activeRowBg;
                }

                rowProps.style = rowStyle;

                return (
                  <Tr
                    {...rowProps}
                    _hover={{
                      //background: "gray.50",
                      bgColor: hoverRowBg
                    }}
                  >
                    {row.cells.map((cell) => {
                      let columnStyle = (cell.column as any).style;
                      return (
                        <Td {...cell.getCellProps()} style={columnStyle}>
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </>
      )}
    </TableContainer>
  );

  const gridView = (
    <Stack>
      {props.isLoading || props.isFetching ? (
        <Stack mt={1}>
          {[...Array(props.pageSize)].map((m, i) => (
            <Skeleton key={i} height="18px" />
          ))}
        </Stack>
      ) : (
        <>
          <Box>
            <List spacing={2}>
              {page.map((row, index) => {
                prepareRow(row);

                const rowProps = {
                  ...row.getRowProps(
                    props.getRowProps ? props.getRowProps(row) : undefined
                  )
                };

                const rowStyle = rowProps.style || {};
                if (
                  props.rowDisabledOnTrue &&
                  props.rowDisabledOnTrue(row.original as unknown as T)
                ) {
                  rowStyle.borderLeftColor = appColors.inactive;
                }

                if (
                  props.rowActiveOnTrue &&
                  props.rowActiveOnTrue(row.original as unknown as T)
                ) {
                  rowStyle.boxShadow = theme.shadows.md;
                  rowStyle.background = activeRowBg;
                }

                rowProps.style = rowStyle;

                return (
                  <ListItem key={index}>
                    <Stack
                      p={2}
                      width="100%"
                      border="1px"
                      borderLeft={"8px"}
                      borderColor="gray.200"
                      borderLeftColor="brand.main.default"
                      {...rowProps}
                    >
                      <HStack alignItems="start">
                        <Stack flexGrow={1} wordBreak="break-all">
                          {row.cells.map((cell, i) => {
                            let isAction = (cell.column as any).isAction;
                            const columnStyle = (cell.column as any).styles;
                            return (
                              !isAction && (
                                <Flex key={i}>
                                  <Text
                                    as="strong"
                                    pr={2}
                                    whiteSpace="nowrap"
                                    sx={{ ...columnStyle }}
                                  >
                                    {cell.column.render("Header")}:
                                  </Text>
                                  <Text borderRightColor="gray.200">
                                    {cell.render("Cell")}
                                  </Text>
                                </Flex>
                              )
                            );
                          })}
                        </Stack>
                        <Stack>
                          {row.cells.map((cell, ii) => {
                            let isAction = (cell.column as any).isAction;
                            return (
                              isAction && (
                                <Fragment key={ii}>
                                  {cell.render("Cell")}
                                </Fragment>
                              )
                            );
                          })}
                        </Stack>
                      </HStack>
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </Stack>
  );

  return (
    <VStack align="stretch">
      <Flex justifyContent="space-between" mb={2} p={0}>
        <HStack w="100%">
          {props.showRecordCount && (
            <Badge alignItems="center" display="flex" px="2" py="1">
              {props.totalRecords || 0} record
              {(props.totalRecords || 0) > 1 && <>s</>}
            </Badge>
          )}
          <InputGroup justifyContent="flex-end">
            <Input
              id="search"
              placeholder="Search"
              name="search"
              w="36%"
              onKeyUp={keyUpHandler}
              onChange={(e) => {
                if (props.localSearch) {
                  setGlobalFilter(e.target.value);
                } else {
                  setSearch(e.target.value);
                }
              }}
              value={props.localSearch ? globalFilter || "" : search}
            />
            <InputRightElement
              children={
                <SearchIcon
                  className="SearchIcon"
                  color={appColors.brand.main.default}
                />
              }
            />
          </InputGroup>
        </HStack>
      </Flex>

      {props.variant === "grid" ? gridView : tableView}

      <Stack>
        {!props.isLoading && page.length === 0 ? (
          <></>
        ) : (
          <Flex justifyContent="end" alignItems="center" mt={4}>
            <HStack hidden={props.hidePagination}>
              <IconButton
                aria-label="first page"
                onClick={() => {
                  gotoPage(0);
                }}
                disabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleLeft />}
              />
              <IconButton
                aria-label="previous page"
                onClick={previousPage}
                disabled={!canPreviousPage || props.isFetching}
                size="xs"
                icon={<CgChevronLeft />}
              />
              <Text>
                {props.pageCount
                  ? `${pageIndex + 1} of ${props.pageCount}`
                  : pageIndex + 1}
              </Text>
              <IconButton
                aria-label="next page"
                onClick={() => {
                  console.log("next page");
                  nextPage();
                }}
                disabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronRight />}
              />
              <IconButton
                aria-label="last page"
                onClick={() => {
                  gotoPage(props.pageCount - 1);
                }}
                disabled={!canNextPage || props.isFetching}
                size="xs"
                icon={<CgChevronDoubleRight />}
              />
            </HStack>
          </Flex>
        )}
      </Stack>
    </VStack>
  );
}

export default CustomTable;
