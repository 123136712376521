import { Grid, GridItem } from "@chakra-ui/layout";
import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import { RefAppDto } from "../../../app/services/pim/types";
import PanelCard from "../../../components/PanelCard";
import AppDemographicList from "./AppDemographicList";
import AppList from "./AppList";
import AppAuth from "../../../features/AppAuth";
import Modal from "./Modal";
import { useGetRefDomainListQuery } from "@/app/services/pim/api/refDomain";

const Apps = () => {
  const [selectedApp, setSelectedApp] = useState<RefAppDto>();
  const refDomainResult = useGetRefDomainListQuery();
  const refAppDomains = refDomainResult.data?.data || [];

  const onAppClick = (refApp: RefAppDto) => {
    setSelectedApp(refApp);
  };

  return (
    <Grid
      templateColumns={`repeat(${selectedApp ? 2 : 1}, minmax(450px, 1fr))`}
      gap={3}
    >
      <GridItem>
        <PanelCard
          header={
            <div style={{ display: "flex" }}>
              <>Apps</>

              <Modal
                action="add"
                fields={{
                  app_name: "",
                  web_url: "",
                  app_alias: "",
                  disabled_flag: false,
                  transmit_flag: false,
                  ref_domain_id: 0,
                  app_support_email: "",
                  app_relay_email: ""
                }}
                trigger={
                  <AppAuth requiredRole={["RefApp.Write"]}>
                    <Button
                      ml={3}
                      leftIcon={<AddIcon />}
                      boxShadow="sm"
                      size="sm"
                    >
                      Add App
                    </Button>
                  </AppAuth>
                }
                refAppDomains={refAppDomains}
              />
            </div>
          }
        >
          <AppList onAppClick={onAppClick} />
        </PanelCard>
      </GridItem>
      {selectedApp && (
        <GridItem>
          <PanelCard
            header={`Demographic - ${selectedApp.app_name}`}
            onClose={() => setSelectedApp(undefined)}
          >
            <AppDemographicList refApp={selectedApp} />
          </PanelCard>
        </GridItem>
      )}
    </Grid>
  );
};

export default Apps;
