import { pimApi } from "./index";
import { AddAppDemographicParam, AppDemographicDto } from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppDemographicByRefAppId: builder.query<Array<AppDemographicDto>, number>({
      query: (ref_app_id) => `/appdemographic/refappid/${ref_app_id}`,
      keepUnusedDataFor: 3,
      providesTags: ["AppDemographic"],
    }),
    postAppDemographic: builder.mutation<void, AddAppDemographicParam>({
      query: (params) => ({
        url: "/AppDemographic",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["AppDemographic", "ForAssignRefDemographic"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAppDemographicByRefAppIdQuery, usePostAppDemographicMutation } = extendedApi;
