import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import UploadAvatar from "../../components/UploadAvatar/UploadAvatar";

interface UploadAvatarModalProps {
  mode: "Add" | "Change";
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  successMessage: string;
  onSubmit: (values: any) => void;
  submitButtonLabel: string;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: any;
  onSuccess: () => void;
}

const UploadAvatarModal: FunctionComponent<UploadAvatarModalProps> = (props) => {
  const [src, setSrc] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const alertDisclosure = useDisclosure();
  const {
    modalTitle,
    isOpen,
    onClose,
    onSubmit,
    submitButtonLabel,
    isSuccess,
    isError,
    isLoading,
    errorMessage,
  } = props;

  const onModalClose = () => {
    setError("");
    setSuccess("");
    setSrc("");
    alertDisclosure.onClose();
    onClose();
  };

  const handleOnChange = (src: string) => {
    setError("");
    setSrc(src);
  };

  const handleOnError = (error: string) => {
    setError(error);
    alertDisclosure.onOpen();
  };

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setSuccess(props.successMessage);
        props.onSuccess();
        setTimeout(() => {
          onModalClose();
        }, 1800);
      } else {
        setSrc("");
        setError("Error " + errorMessage.status);
      }
    }
  }, [isSuccess, isError, errorMessage]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5} align="stretch">
              <HStack spacing={7} alignItems="flex-start"></HStack>
              <UploadAvatar onChange={handleOnChange} onError={handleOnError} />
              {alertDisclosure.isOpen && (
                <>
                  {success && (
                    <Alert status="success">
                      <AlertIcon />
                      {props.successMessage}
                    </Alert>
                  )}
                  {error && (
                    <Alert status="error">
                      <AlertIcon />
                      {error}
                    </Alert>
                  )}
                </>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onModalClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="brand.main"
              isLoading={isLoading}
              onClick={() => {
                src && onSubmit(src);
              }}
            >
              {submitButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadAvatarModal;
