import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { convertDemographicNameToLabel } from ".";
import {
  useGetUserDemographicByRefUserIdQuery,
  useLazyGetUserDemographicByRefUserIdQuery,
  usePostUserDemographicByRefUserIdMutation,
  usePutUserDemographicByRefUserIdMutation,
} from "../../app/services/pim/api/userDemographics";
import { RefUserDto } from "../../app/services/pim/types";
import {
  PutUserDemographicParamDto,
  UserDemographicDto,
} from "../../app/services/pim/types/userDemographicTypes";
import { setLogonUser } from "../../app/slices/userSlice";
import { useAppSelector } from "../../app/state/hooks";

interface UpdateDemographicsButtonProps {
  headerTitle?: string;
  title: string;
  refUserId: number | null;
  children?: JSX.Element | string;
  onSuccess: () => void;
}

const EditUserDemographicModal = ({
  headerTitle,
  title,
  refUserId,
  children,
  onSuccess,
}: UpdateDemographicsButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [putAsync, { isLoading }] = usePutUserDemographicByRefUserIdMutation();
  const [postAsync] = usePostUserDemographicByRefUserIdMutation();
  const [trigger, demographicQuery] = useLazyGetUserDemographicByRefUserIdQuery();
  const { logonUser } = useAppSelector((s) => s.user);
  const dispatch = useDispatch();

  const [demographicsForAdd, setDemographicsForAdd] = useState<UserDemographicDto[]>([
    {
      user_demographic_id: 0,
      ref_demographic_id: Number(process.env.REACT_APP_REFDEMOGRAPHIC_FIRSTNAME_ID),
      demographic_name: "first_name",
      ref_user_id: 0,
      email: "",
      demographic_value: "",
      disabled_datetime_utc: "",
      disabled_flag: false,
      row_created_datetime_utc: "",
      row_modified_datetime_utc: "",
    },
    {
      user_demographic_id: 0,
      ref_demographic_id: Number(process.env.REACT_APP_REFDEMOGRAPHIC_LASTNAME_ID),
      demographic_name: "last_name",
      ref_user_id: 0,
      email: "",
      demographic_value: "",
      disabled_datetime_utc: "",
      disabled_flag: false,
      row_created_datetime_utc: "",
      row_modified_datetime_utc: "",
    },
    {
      user_demographic_id: 0,
      ref_demographic_id: Number(process.env.REACT_APP_REFDEMOGRAPHIC_MIDDLENAME_ID),
      demographic_name: "middle_name",
      ref_user_id: 0,
      email: "",
      demographic_value: "",
      disabled_datetime_utc: "",
      disabled_flag: false,
      row_created_datetime_utc: "",
      row_modified_datetime_utc: "",
    },
  ]);

  const [putUserDemogStatus, setPutUserDemogStatus] = useState<any | null>({
    isSuccess: false,
    isError: false,
    error: null,
  });

  const resetAlert = () => {
    setPutUserDemogStatus({
      isSuccess: false,
      isError: false,
      error: null,
    });
    alertDisclosure.onClose();
  };

  const onUpdate = (userDemographic: PutUserDemographicParamDto) => {
    try {
      let operation = isEditMode ? putAsync(userDemographic) : postAsync(userDemographic);
      operation.then((resp: any) => {
        alertDisclosure.onOpen();
        if (resp.error) {
          const errors = resp.error.data.errors;
          let finalError = {};
          if (errors) {
            finalError = {
              ...putUserDemogStatus,
              isError: true,
              error: Object.values(errors),
            };
          } else {
            finalError = {
              ...putUserDemogStatus,
              isError: true,
              error: Object.values(`Error ${resp.error.status}`),
            };
          }
          setPutUserDemogStatus(finalError);
        } else {
          setPutUserDemogStatus({
            ...putUserDemogStatus,
            isSuccess: true,
            error: null,
          });

          //update logonuser info after adding / updating demographics
          if (userDemographic.ref_user_id === logonUser?.ref_user_id) {
            let newUserData: RefUserDto = {
              ...logonUser,
              ref_user_id: logonUser?.ref_user_id || 0,
              first_name:
                userDemographic.demographicList.find((f) => f.demographic_name === "first_name")
                  ?.demographic_value || "",
              last_name:
                userDemographic.demographicList.find((f) => f.demographic_name === "last_name")
                  ?.demographic_value || "",
              email: logonUser?.email || "",
            };

            dispatch(setLogonUser(newUserData));
          }

          setTimeout(() => {
            onSuccess();
            onClose();
            alertDisclosure.onClose();
          }, 1800);
        }
      });
    } catch (error) {
      onOpen();
    }
  };

  const { handleSubmit, values, resetForm, setValues } = useFormik({
    enableReinitialize: true,
    //validationSchema: FormSchema,
    initialValues:
      demographicQuery.data && demographicQuery.data.length > 0
        ? demographicQuery.data
        : demographicsForAdd,
    onSubmit: (values) => {
      resetAlert();
      let param: PutUserDemographicParamDto = {
        ref_user_id: 0,
        demographicList: [],
      };
      const demographicList: UserDemographicDto[] = [];
      const id = refUserId || 0;

      if (values && values.length > 0) {
        values.map((d: UserDemographicDto) => {
          demographicList.push({
            ...d,
          });
        });
      }
      param.ref_user_id = id;
      param.demographicList = demographicList;
      onUpdate(param);
    },
  });

  useEffect(() => {
    if (!demographicQuery.isFetching) {
      if (demographicQuery.data && demographicQuery.data.length > 0) {
        setIsEditMode(true);
      } else {
        setIsEditMode(false);
      }
    }
  }, [demographicQuery.data]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
      resetAlert();
    } else {
      trigger(refUserId || 0);
    }
  }, [isOpen]);

  return (
    <div>
      {children ? (
        <span onClick={onOpen}>{children}</span>
      ) : (
        <Button
          leftIcon={<FiEdit />}
          boxShadow="sm"
          size="sm"
          onClick={onOpen}
          colorScheme="brand.main"
        >
          {title}
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit}>
          <ModalContent>
            <ModalHeader>{headerTitle || "Update"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5} align="stretch">
                <VStack spacing={5} alignItems="flex-start">
                  {values && !demographicQuery.isFetching ? (
                    values.map((d: any, i: number) => {
                      return (
                        <FormControl isRequired={d.required_field_flag} key={i}>
                          <FormLabel htmlFor={d.demographic_name}>
                            {convertDemographicNameToLabel(d.demographic_name)}
                          </FormLabel>
                          <Input
                            id={d.demographic_name}
                            placeholder={convertDemographicNameToLabel(d.demographic_name)}
                            name={d.demographic_name}
                            value={d.demographic_value}
                            onChange={(e) => {
                              const newDemographics = values?.map((d: any) => {
                                if (d.demographic_name === e.target.name) {
                                  return {
                                    ...d,
                                    demographic_value: e.target.value,
                                  };
                                } else {
                                  return d;
                                }
                              });
                              setValues(newDemographics);
                              setDemographicsForAdd(newDemographics);
                            }}
                          />

                          {/* <FormErrorMessage>{demographicsErrors.demographic_name}</FormErrorMessage> */}
                        </FormControl>
                      );
                    })
                  ) : (
                    <div>Loading...</div>
                  )}
                </VStack>
              </VStack>
            </ModalBody>
            {alertDisclosure.isOpen && (
              <Alert
                status={
                  putUserDemogStatus.isSuccess
                    ? "success"
                    : putUserDemogStatus.isError
                    ? "error"
                    : "info"
                }
              >
                <AlertIcon />
                <Box flexGrow={1}>
                  <AlertDescription>
                    {putUserDemogStatus.isSuccess && `Successfully updated.`}
                    {putUserDemogStatus.isError && putUserDemogStatus.error}
                  </AlertDescription>
                </Box>
                {putUserDemogStatus.isError && (
                  <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    right={-1}
                    top={-1}
                    onClick={alertDisclosure.onClose}
                  />
                )}
              </Alert>
            )}
            {values && values.length > 0 && (
              <ModalFooter>
                <Button mr={3} onClick={onClose} disabled={isLoading}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.main"
                  isLoading={isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              </ModalFooter>
            )}
          </ModalContent>
        </form>
      </Modal>
    </div>
  );
};

export default EditUserDemographicModal;
