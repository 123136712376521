/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import {
  VStack,
  Checkbox,
  Text,
  Divider,
  HStack,
  Button
} from "@chakra-ui/react";

import { UpdateUserRoleParam, RefUserDto } from "@/app/services/pim/types";
import { useGetRefRoleListQuery } from "@/app/services/pim/api/refRole";
import AppAuth from "@/features/AppAuth";
import useRoles from "@/hooks/useRoles";

import {
  useGetUserRoleListByRefUserIdQuery,
  usePutUserRoleMutation
} from "@/app/services/pim/api/userRole";

interface IProps {
  user: RefUserDto;
}

const UserRole: FC<IProps> = ({ user }) => {
  const refRoleQry = useGetRefRoleListQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const userRoleQry = useGetUserRoleListByRefUserIdQuery(user.ref_user_id, {
    refetchOnMountOrArgChange: true
  });
  const [putAsync, putStatus] = usePutUserRoleMutation();

  const [userRoleList, setUserRoleList] = useState<UpdateUserRoleParam[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const { hasRoles } = useRoles();
  const hasWrite = hasRoles(["UserRole.Write", "SuperAdmin"]);

  useEffect(() => {
    if (userRoleQry.data) {
      setUserRoleList(
        userRoleQry.data.map((m) => ({ ref_role_id: m.ref_role_id }))
      );
    } else {
      setUserRoleList([]);
    }
  }, [userRoleQry.data]);

  useEffect(() => {
    checkUserRoleChanges();
  }, [userRoleList]);

  useEffect(() => {
    !userRoleQry.isFetching && checkUserRoleChanges();
  }, [userRoleQry.isFetching]);

  const updateCheckedRoles = (ref_role_id: number, isChecked: boolean) => {
    if (isChecked) {
      setUserRoleList((s) => [...s, { ref_role_id }]);
    } else {
      setUserRoleList((s) => s.filter((f) => f.ref_role_id !== ref_role_id));
    }
  };

  const updateUserRoles = async () => {
    await putAsync({ ref_user_id: user.ref_user_id, model: userRoleList });
  };

  const checkUserRoleChanges = () => {
    const a = JSON.stringify(userRoleList.sort(refRoleSorter));
    const b = JSON.stringify(
      userRoleQry.data
        ?.map(({ ref_role_id }) => ({ ref_role_id }))
        .sort(refRoleSorter) || "{}"
    );

    setDisableSaveButton(a === b);
  };

  const refRoleSorter = (a: UpdateUserRoleParam, b: UpdateUserRoleParam) => {
    return a.ref_role_id - b.ref_role_id;
  };

  return (
    <VStack align="stretch" spacing={4}>
      <VStack spacing={2} alignItems="flex-start">
        {refRoleQry.isFetching || userRoleQry.isFetching ? (
          <>Loading...</>
        ) : refRoleQry.isSuccess && userRoleQry.isSuccess ? (
          <>
            <AppAuth requiredRole={["UserRole.Read", "SuperAdmin"]}>
              {(isAuthorized) => (
                <>
                  {isAuthorized ? (
                    <>
                      <Text size="md" fontWeight="bold">
                        Check on the boxes to assign role
                      </Text>

                      {refRoleQry.data.map((role, index) => {
                        const isChecked = userRoleList.some(
                          (s) => s.ref_role_id === role.ref_role_id
                        );

                        return hasWrite ? (
                          <Checkbox
                            disabled={
                              !isAuthorized ||
                              putStatus.isLoading ||
                              Boolean(user.disabled_datetime_utc)
                            }
                            name={`UserRoleUserId_${user.ref_user_id}`}
                            onChange={(e: any) => {
                              updateCheckedRoles(
                                role.ref_role_id,
                                e.target.checked
                              );
                            }}
                            isChecked={isChecked}
                          >
                            {role.role_name}
                          </Checkbox>
                        ) : (
                          isChecked && <p>{role.role_name}</p>
                        );
                      })}
                    </>
                  ) : (
                    <p>You do not have permission to access this page.</p>
                  )}
                </>
              )}
            </AppAuth>
          </>
        ) : (
          <>Please try again later or contact admin</>
        )}
      </VStack>

      {!user.disabled_datetime_utc ? (
        <>
          <Divider />
          <HStack justifyContent="flex-end">
            <AppAuth requiredRole={["UserRole.Write", "SuperAdmin"]}>
              <Button
                size="sm"
                colorScheme="brand.main"
                onClick={updateUserRoles}
                isLoading={putStatus.isLoading}
                disabled={disableSaveButton || userRoleQry.isFetching}
              >
                Save
              </Button>
            </AppAuth>
          </HStack>
        </>
      ) : (
        <></>
      )}
    </VStack>
  );
};

export default UserRole;
