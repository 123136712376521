//reference -> https://dev.azure.com/Sequelae/c75ae3dc-5811-4e81-9649-a565b1ece1b9/_apis/git/repositories/cd1ee74d-6c0e-4073-9555-8c6fd64f7d2f/Items?path=/.attachments/BrandColors-28ea31b5-f599-443b-839d-a72f5123e822.png&download=false&resolveLfs=true&%24format=octetStream&api-version=5.0-preview.1&sanitize=true&versionDescriptor.version=wikiMaster
//reference update 1 -> https://dev.azure.com/Sequelae/c75ae3dc-5811-4e81-9649-a565b1ece1b9/_apis/git/repositories/cd1ee74d-6c0e-4073-9555-8c6fd64f7d2f/Items?path=/.attachments/BrandColors-be030421-aa1a-4d37-8631-d3fa2b7174c2.png&download=false&resolveLfs=true&%24format=octetStream&api-version=5.0-preview.1&sanitize=true&versionDescriptor.version=wikiMaster
export const brandColors = {
  primary: {
    green: {
      100: "#C7EFB4",
      200: "#8ED372",
      300: "#4DB748",
      400: "#377524",
    },
    teal: {
      100: "#9BE1E1",
      200: "#50C0CC",
      300: "#3794AA",
      400: "#13707F",
    },
    whiteBlack: {
      white: "#FFFFFF",
      100: "#F2F2F2",
      200: "#D8D8D8",
      300: "#888888",
      400: "#595959",
      black: "#000000",
    },
  },
  secondary: {
    blue: {
      200: "#0A7AFF",
      300: "#1A5A99",
    },
    violet: {
      200: "#9A88E8",
      300: "#6A5AA3",
    },
    orange: {
      200: "#FFBB17",
      300: "#E58C17",
    },
    green: {
      200: "#88B835",
      300: "#468822",
    },
  },
  alert: {
    red: {
      200: "#FF7871",
      300: "#D13745",
    },
  },
};

export default brandColors;
