import { AddIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useGetRefAppListQuery } from "../../app/services/pim/api/refApp";
import { usePostUserApplicationMutation } from "../../app/services/pim/api/user";
import {
  AppSSOUserDto,
  PostUserApplicationDto,
  RefAppDto,
  RefUserDto,
} from "../../app/services/pim/types";
import AppAuth from "../../features/AppAuth";
import * as Yup from "yup";
import { usePutUserDemographicByRefUserIdMutation } from "../../app/services/pim/api/userDemographics";
import { PutUserDemographicParamDto } from "../../app/services/pim/types/userDemographicTypes";

interface IAddUserAppModalProps {
  user?: RefUserDto;
  currentUserApps?: AppSSOUserDto[];
}

const AddUserAppModal: FC<IAddUserAppModalProps> = ({ user, currentUserApps }) => {
  const [availableApps, setavailableApps] = useState<RefAppDto[]>([]);
  const [sso_object_id, setSso_object_id] = useState("");
  const [ssoObjectMessage, setssoObjectMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();
  const refAppResult = useGetRefAppListQuery();

  const [postAsync, { isLoading, isSuccess, isError, error }] = usePostUserApplicationMutation();
  const [putDemographicsAsync] = usePutUserDemographicByRefUserIdMutation();

  const initialValues = {
    app_guid: "",
  } as PostUserApplicationDto;

  useEffect(() => {
    if (currentUserApps && currentUserApps.length) {
      setSso_object_id(currentUserApps[0].sso_object_id);
    } else {
      setSso_object_id("");
    }
  }, [currentUserApps]);

  useEffect(() => {
    setssoObjectMessage("No SSO Object ID found, cannot assign user");
  }, [user]);

  const postSubmit = async (values: PostUserApplicationDto) => {
    const params = {
      app_guid: values.app_guid,
      email: user?.email || "",
      sso_name: process.env.REACT_APP_SSO_NAME || "",
      source:
        values.app_guid.toLowerCase() === process.env.REACT_APP_GHP_APP_GUID?.toLowerCase()
          ? "graph"
          : "", // temporary: to be removed after GHP migration
      sso_object_id: sso_object_id,
    };
    postAsync(params).then((res: any) => {
      if (res.data) {
        putDemographicsAsync({
          ref_user_id: user?.ref_user_id,
          demographicList: [
            {
              demographic_name: "first_name",
              demographic_value: user?.first_name,
            },
            {
              demographic_name: "last_name",
              demographic_value: user?.last_name,
            },
          ],
        } as PutUserDemographicParamDto);
      }
    });

    //update demographics
  };

  const FormSchema = Yup.object().shape({
    app_guid: Yup.string().required("Application is required"),
  });

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: initialValues,
    onSubmit: (values) => {
      const val = {
        ...values,
      };
      postSubmit(val);
    },
  });

  useEffect(() => {
    if (refAppResult.data && isOpen) {
      let filteredApps = refAppResult.data.filter(
        (f) => !currentUserApps?.some((s) => s.ref_app_id === f.ref_app_id) && !f.disabled_flag
      );
      setavailableApps(filteredApps);
    }
  }, [refAppResult.data, isOpen]);

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          onClose();
          resetForm();
          alertDisclosure.onClose();
        }, 2000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  return (
    <>
      <Stack align="start">
        <AppAuth requiredRole={["User.Write"]}>
          <>
            {!user?.disabled_datetime_utc && (
              <>
                <Button
                  leftIcon={<AddIcon />}
                  boxShadow="sm"
                  size="sm"
                  onClick={onOpen}
                  disabled={!sso_object_id}
                >
                  Assign User
                </Button>
                {!sso_object_id && (
                  <Text fontSize="sm" color="brand.error">
                    {ssoObjectMessage}
                  </Text>
                )}
              </>
            )}
          </>
        </AppAuth>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <form onSubmit={handleSubmit}>
          {
            <ModalContent>
              <ModalHeader>Assign User</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                {availableApps && (
                  <>
                    <FormControl mb={3} isInvalid={!!errors.app_guid && touched.app_guid}>
                      <FormLabel>Choose Application</FormLabel>
                      <Select
                        id="app_guid"
                        name="app_guid"
                        value={`${values.app_guid}`}
                        onChange={handleChange}
                      >
                        <option key={0} value="">
                          Please select
                        </option>
                        {availableApps.map((item, index) => (
                          <option value={item.app_guid} key={index + 1}>
                            {item.app_name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.app_guid}</FormErrorMessage>
                    </FormControl>
                  </>
                )}

                {alertDisclosure.isOpen && (
                  <Alert status={isSuccess ? "success" : isError ? "error" : "info"}>
                    <AlertIcon />
                    {isSuccess && "Application added"}
                    {isError &&
                      !error &&
                      "Something went wrong, please try again later or contact admin"}
                    {errors.email}
                  </Alert>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  colorScheme="brand.main"
                  mr={2}
                  type="submit"
                  isLoading={isLoading}
                >
                  Save
                </Button>
                <Button onClick={onClose} size="sm">
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          }
        </form>
      </Modal>
    </>
  );
};

export default AddUserAppModal;
