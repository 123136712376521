import { pimApi } from "./index";
import type { IRefDomainFields, RefDomainPageDto } from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getRefDomainList: builder.query<RefDomainPageDto, void>({
      query: (searchString) => {
        return `/refDomain/list`;
      }
    }),
    postRefDomain: builder.mutation<void, { model: IRefDomainFields }>({
      query: (params) => ({
        url: `/refDomain`,
        method: "POST",
        body: params.model,
        responseHandler: "text"
      })
    })
  }),
  overrideExisting: false
});

export const { useGetRefDomainListQuery, usePostRefDomainMutation } =
  extendedApi;
