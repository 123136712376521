import {
  Button,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from "react-icons/hi";
import { FC, Fragment } from "react";
import { navItems } from "./navItems";
import AppAuth from "../../features/AppAuth";
import NavItem from "./NavItem";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import PageLoading from "../PageLoading";
import { appColors } from "../../app/theme";
import AuthenticatedUserWrapper from "../../features/AuthenticatedUserWrapper";

type Props = {};

const SideNav: FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  return (
    <>
      <Flex
        direction="column"
        id="SideNav"
        minH="100vh"
        px={1}
        py={1}
        overflowX="hidden"
        w={isOpen ? "252px" : "50px"}
      >
        {isOpen ? (
          <IconButton
            maxW="30px"
            ml="auto"
            aria-label="Hide"
            onClick={onClose}
            icon={<HiOutlineChevronDoubleLeft />}
          />
        ) : (
          <IconButton
            maxW="30px"
            ml="auto"
            aria-label="Show"
            onClick={onOpen}
            icon={<HiOutlineChevronDoubleRight />}
          />
        )}
        <AuthenticatedUserWrapper>
          <Divider mt={1} />
          {navItems.map((m, i) => (
            <Fragment key={i}>
              {m.requiredRole ? (
                <AppAuth key={m.text} requiredRole={m.requiredRole}>
                  <NavItem item={m} />
                </AppAuth>
              ) : (
                <NavItem item={m} />
              )}
            </Fragment>
          ))}
        </AuthenticatedUserWrapper>
      </Flex>
    </>
  );
};

export default SideNav;
