import { AuthenticatedTemplate, useAccount, useIsAuthenticated } from "@azure/msal-react";
import React, { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getIdTokenClaims } from "../../app/services/auth/helper";
import { useLazyGetUserByEmailQuery } from "../../app/services/pim/api/user";
import { setLogonUser } from "../../app/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/state/hooks";
import PageLoading from "../../components/PageLoading";

interface IProps {
  children: ReactNode;
}

const AuthenticatedUserWrapper: FC<IProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();

  const navigate = useNavigate();

  const { logonUser } = useAppSelector((s) => s.user);
  const dispatch = useAppDispatch();

  const [lazyGetUserByEmail, queryResult] = useLazyGetUserByEmailQuery();

  useEffect(() => {
    const { emails = [] } = getIdTokenClaims(account) || {};

    if (isAuthenticated && emails?.length && !logonUser) {
      lazyGetUserByEmail(emails[0]);
    }
    if (logonUser?.disabled_flag) {
      navigate("/unauthorized");
    }
  }, [isAuthenticated, logonUser]);

  useEffect(() => {
    if (queryResult.isSuccess) {
      dispatch(setLogonUser(queryResult.data));
    }
    if (queryResult.isError) {
      navigate("/unauthorized");
    }
  }, [queryResult, isAuthenticated]);

  return (
    <>
      {!logonUser && (queryResult.isLoading || queryResult.isFetching) ? (
        <PageLoading />
      ) : (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      )}
    </>
  );
};

export default AuthenticatedUserWrapper;
