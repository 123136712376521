import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WindowSize } from "../types/appType";
export interface AppState {
  isShowMobileTab: boolean;
  windowSize: WindowSize;
}

const initialState: AppState = { isShowMobileTab: false, windowSize: { width: 0, height: 0 } };

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsShowMobileTab: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileTab = action.payload;
    },
    setWindowSize: (state, action: PayloadAction<WindowSize>) => {
      state.windowSize = action.payload;
    },
  },
});

export const { setIsShowMobileTab, setWindowSize } = appSlice.actions;
export default appSlice.reducer;
