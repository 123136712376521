import {
  Box,
  HStack,
  IconButton,
  VStack,
  Flex,
  Text,
  SimpleGrid,
  useBoolean,
  Tooltip,
  ButtonGroup,
  useColorModeValue
} from "@chakra-ui/react";
import { FC, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import { AppSSOUserDto, RefUserDto } from "../../../app/services/pim/types";
import AddUserAppModal from "../AddUserAppModal";
import { BsFillEyeFill } from "react-icons/bs";
import { useGetAppSSOUserByRefUserIdQuery } from "../../../app/services/pim/api/appSSOUser";
import TooltipLabelValue from "../../../components/TooltipLabelValue";
import { MdInfo } from "react-icons/md";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import GridViewSwitch from "../../../components/GridViewSwitch";

interface IUserApplicationListProps {
  selectedUser: RefUserDto;
  selectedApp: AppSSOUserDto | null;
  onClickUser: (user: RefUserDto | null) => void;
  onClickApp: (userApp: AppSSOUserDto | null) => void;
}

const UserApplicationList: FC<IUserApplicationListProps> = ({
  selectedUser,
  selectedApp,
  onClickUser,
  onClickApp
}) => {
  const [pageNumber] = useState(0);
  const [pageSize] = useState(20);
  const [search] = useState("");
  const [gridViewFlag, setGridViewFlag] = useBoolean(true);
  const textColor = useColorModeValue("inherit", "whiteAlpha.700");

  const { data, isLoading, isFetching } = useGetAppSSOUserByRefUserIdQuery(
    selectedUser.ref_user_id
  );
  const TableHeader = [
    {
      Header: "Action",
      isAction: true,
      style: { display: "flex", justifyContent: "center" },
      Cell: ({ row: { original } }: { row: { original: AppSSOUserDto } }) => {
        return (
          <ButtonGroup spacing={1}>
            <Tooltip
              label={
                <TooltipLabelValue
                  labelValueList={[
                    { label: "ID", value: original.ref_app_id },
                    {
                      label: "App Disabled",
                      value:
                        original.disabled_flag || original.ref_app_disabled_flag
                          ? "Yes"
                          : "No"
                    },
                    {
                      label: "App Disabled Date",
                      value:
                        convertUtcToLocal(original.disabled_datetime_utc) ||
                        convertUtcToLocal(original.disabled_date)
                    }
                  ]}
                  auditFieldsObject={original}
                />
              }
            >
              <IconButton
                minWidth={1}
                variant="link"
                aria-label="View Details"
                icon={<MdInfo />}
              />
            </Tooltip>
            <Tooltip label="View Events">
              <IconButton
                minWidth={1}
                variant="link"
                aria-label="View Events"
                icon={<BsFillEyeFill />}
                onClick={() => {
                  onClickApp(original);
                }}
              />
            </Tooltip>
          </ButtonGroup>
        );
      }
    },
    {
      Header: "App Name",
      accessor: "app_name"
    },
    {
      Header: "SSO Name",
      accessor: "sso_name"
    },
    {
      Header: "SSO Id",
      accessor: "sso_object_id"
    }
  ];

  return (
    <Box>
      <VStack align="stretch" spacing={5}>
        {!isLoading && !isFetching && (
          <Box
            //p={2}
            width="100%"
            //border="1px" borderColor="gray.200" bg="gray.100"
            //boxShadow="sm"
          >
            <SimpleGrid
              columns={2}
              spacing={2}
              // fontSize="sm"
            >
              <Box>
                <Flex>
                  <Text color={textColor} as="strong" pr={2}>
                    First Name:
                  </Text>
                  <Text borderRightColor="gray.200">
                    {selectedUser.first_name}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex>
                  <Text color={textColor} as="strong" pr={2}>
                    Last Name:
                  </Text>
                  <Text borderRightColor="gray.200">
                    {selectedUser.last_name}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex>
                  <Text color={textColor} as="strong" pr={2}>
                    Email:
                  </Text>
                  <Text borderRightColor="gray.200">{selectedUser.email}</Text>
                </Flex>
              </Box>
            </SimpleGrid>
          </Box>
        )}

        <GridViewSwitch
          isChecked={gridViewFlag}
          onChange={setGridViewFlag.toggle}
        />

        <HStack align="stretch" justifyContent="space-between">
          <HStack alignItems="center" spacing={2}>
            {!isLoading && !isFetching && (
              <AddUserAppModal user={selectedUser} currentUserApps={data} />
            )}
          </HStack>
        </HStack>
        <CustomTable
          variant={gridViewFlag ? "grid" : "table"}
          isLoading={isLoading}
          isFetching={isFetching}
          data={data ?? []}
          pageCount={pageNumber || 0}
          pageSize={pageSize}
          totalRecords={data?.length || 0}
          headers={TableHeader}
          search={search}
          hidePagination
          onPageChange={(index) => {}}
          onPageSizeChange={() => {}}
          onPageSearch={() => {}}
          onSort={() => {}}
          rowDisabledOnTrue={(row) =>
            Boolean(row.disabled_flag || row.ref_app_disabled_flag)
          }
          rowActiveOnTrue={(row) => row.ref_app_id === selectedApp?.ref_app_id}
          tableSort
        />
      </VStack>
    </Box>
  );
};

export default UserApplicationList;
