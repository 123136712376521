import { IconButton } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { useEffect, useState } from "react";
import { MdInfo } from "react-icons/md";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import { useDebounce } from "../../../app/helpers/stringHelper";
import { useGetRefDemographicListQuery } from "../../../app/services/pim/api/refDemographic";
import { RefDemographicDto } from "../../../app/services/pim/types";
import CustomTable from "../../../components/CustomTable";
import PanelCard from "../../../components/PanelCard";
import TooltipLabelValue from "../../../components/TooltipLabelValue";
import AppAuth from "../../../features/AppAuth";
import AddDemographicModal from "./AddDemographicModal";

const RefDemographicTableHeader = [
  {
    Header: "Action",
    style: { textAlign: "center" },
    accessor: "ref_demographic_id",
    Cell: ({ row: { original } }: { row: { original: RefDemographicDto } }) => {
      return (
        <Flex justifyContent="center">
          <Tooltip
            label={
              <TooltipLabelValue
                labelValueList={[
                  { label: "ID", value: original.ref_demographic_id },
                  {
                    label: "Disabled",
                    value: original.disabled_flag ? "Yes" : "No"
                  },
                  {
                    label: "Disabled Date",
                    value: convertUtcToLocal(original.disabled_datetime_utc)
                  }
                ]}
                auditFieldsObject={original}
              />
            }
          >
            <IconButton
              minWidth={1}
              variant="link"
              aria-label="View Details"
              icon={<MdInfo />}
            />
          </Tooltip>
        </Flex>
      );
    }
  },
  {
    Header: "Name",
    accessor: "demographic_name"
  },
  {
    Header: "Description",
    accessor: "demographic_desc"
  },
  {
    Header: "Type",
    accessor: "data_type"
  },
  {
    Header: "Length",
    accessor: "data_length"
  },
  {
    Header: "Format",
    accessor: "data_format"
  }
];

type Props = {};

const Demographic = (props: Props) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [searchDebounce, setSearchDebounce] = useState("");
  const debouncedSearch = useDebounce(search, 400);
  const refDemograhicResult = useGetRefDemographicListQuery(
    new URLSearchParams({
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      q: searchDebounce
    }).toString()
  );
  useEffect(() => {
    setSearchDebounce(debouncedSearch);
  }, [debouncedSearch]);
  return (
    <PanelCard
      w="full"
      header={
        <>
          <>Demographic</>
          <AppAuth requiredRole={["RefDemographic.Write"]}>
            <AddDemographicModal />
          </AppAuth>
        </>
      }
    >
      <CustomTable
        variant="table"
        headers={RefDemographicTableHeader}
        isLoading={refDemograhicResult.isLoading}
        isFetching={refDemograhicResult.isFetching}
        data={refDemograhicResult.data?.data ?? []}
        pageCount={refDemograhicResult.data?.total_pages || 0}
        pageSize={refDemograhicResult.data?.page_size || pageSize}
        totalRecords={refDemograhicResult.data?.total_records || 0}
        showRecordCount
        search={search}
        onPageChange={(index) => {
          setPageNumber(index);
        }}
        onPageSizeChange={(size) => {
          setPageSize(size);
        }}
        onPageSearch={(search) => {
          setPageNumber(1);
          setSearch(search);
        }}
        onSort={() => {}}
        rowDisabledOnTrue={(row) => Boolean(row.disabled_flag)}
      />
    </PanelCard>
  );
};

export default Demographic;
