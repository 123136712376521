import { Box, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import b64toBlob from "b64-to-blob";
import { FunctionComponent } from "react";
import { AiFillCamera } from "react-icons/ai";
import { usePostAvatarMutation } from "../../app/services/pim/api/avatar";
import { RefUserDto } from "../../app/services/pim/types";
import UploadAvatarModal from "./UploadAvatarModal";

interface UploadAvatarButtonProps {
  title: string;
  logonUser: RefUserDto;
  onSuccess: () => void;
}

const UploadAvatarButton: FunctionComponent<UploadAvatarButtonProps> = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [postAsync, { isLoading, isSuccess, isError, error }] = usePostAvatarMutation();

  const onSubmit = (src: string) => {
    if (src) {
      const contentType = "image/jpeg";
      const b64Data = src.split(",")[1];

      const blob = b64toBlob(b64Data, contentType);

      const formData = new FormData();
      formData.append("File", blob);
      formData.append("email", props.logonUser.email);

      postAsync(formData);
    }
  };

  return (
    <Box p={3}>
      <Tooltip hasArrow label="Change Picture" bg="gray.100" placement="top">
        <IconButton
          size="lg"
          variant="link"
          aria-label="Change Picture"
          icon={<AiFillCamera />}
          onClick={onOpen}
        />
      </Tooltip>
      <UploadAvatarModal
        mode="Add"
        modalTitle="Upload Photo"
        isOpen={isOpen}
        onClose={onClose}
        submitButtonLabel="Submit"
        successMessage="Successfully saved photo."
        onSubmit={onSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage={error}
        onSuccess={props.onSuccess}
      />
    </Box>
  );
};

export default UploadAvatarButton;
