import {
  SimpleGrid,
  Flex,
  Box,
  Text,
  useColorModeValue
} from "@chakra-ui/react";

import React, { FC, useEffect } from "react";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import { useGetGraphUserByEmailQuery } from "../../../app/services/pim/api/graph";
import { RefUserDto } from "../../../app/services/pim/types";
import { GetGraphUser } from "../../../app/services/pim/types/graphTypes";

interface IProps {
  email: string;
}

function getAdditionalData(data: GetGraphUser, key: string): string | null {
  let props = null;

  if (data.additionalData) {
    Object.keys(data.additionalData).forEach((prop: string) => {
      if (prop.includes(key)) {
        props = data.additionalData[prop];
      }
    });
  }

  return typeof props == "boolean"
    ? props === true
      ? "Yes"
      : "No"
    : props ?? "";
}
const B2CInfo: FC<IProps> = ({ email }) => {
  const textColor = useColorModeValue("inherit", "whiteAlpha.700");

  const { data, isLoading, isFetching, error, isError } =
    useGetGraphUserByEmailQuery(email);

  useEffect(() => {
    if (isError) {
    }
  }, [isError]);

  function utcToLocal(dateTimeUTc: string | null): string {
    if (dateTimeUTc) return convertUtcToLocal(dateTimeUTc);
    return "";
  }

  return (
    <Box width="100%">
      {isError && (
        <Box>
          <Flex>
            <Text color={textColor} as="strong" pr={2}>
              No data found.
            </Text>
          </Flex>
        </Box>
      )}
      {!isError && !isLoading && data && (
        <SimpleGrid columns={1} spacing={2}>
          <Box>
            <Flex>
              <Text color={textColor} as="strong" pr={2}>
                Object Id :
              </Text>
              <Text borderRightColor="gray.200">{data.id}</Text>
            </Flex>
          </Box>
          <Box>
            <Flex>
              <Text color={textColor} as="strong" pr={2}>
                User Has Signed-In :
              </Text>
              <Text borderRightColor="gray.200">
                {getAdditionalData(data, "Userhassignedin")}
              </Text>
            </Flex>
          </Box>

          <Box>
            <Flex>
              <Text color={textColor} as="strong" pr={2}>
                First Signed-In Date and Time :
              </Text>
              <Text borderRightColor="gray.200">
                {utcToLocal(
                  getAdditionalData(data, "Userfirstsignindatetimeutc")
                )}
              </Text>
            </Flex>
          </Box>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default B2CInfo;
