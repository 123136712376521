import { FC, useEffect, useState } from "react";
import {
  Flex,
  useColorModeValue,
  Stack,
  Button,
  useDisclosure,
  Heading,
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuGroup,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
} from "@chakra-ui/react";
import {
  AuthenticatedTemplate,
  useAccount,
  useMsal,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../app/state/hooks";
import { isCommunityPage } from "../../app/helpers/navigationHelpers";
import { loginRequest } from "../../app/services/auth/authConfig";
import { persistor } from "../../app/state/store";
import { appColors } from "../../app/theme";
import AppAuth from "../../features/AppAuth";
import { useLazyGetAvatarByEmailQuery } from "../../app/services/pim/api/avatar";

interface IProps {}

const Header: FC<IProps> = () => {
  const account = useAccount();
  const instance = globalThis.msalInstance;
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { isShowMobileTab } = useAppSelector((s) => s.app);
  const { onClose } = useDisclosure();
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [getAvatarTrigger, { data, isLoading, isFetching }] = useLazyGetAvatarByEmailQuery();

  const { logonUser } = useAppSelector((s) => s.user);
  const isTest = process.env.REACT_APP_ENV === "TEST";

  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (isAuthenticated) {
      const { idTokenClaims } = account as any;
      const upn = account?.username || (idTokenClaims.emails && idTokenClaims.emails[0]) || "";
      setUserEmail(upn);
    }
  }, [account, isAuthenticated]);

  useEffect(() => {
    if (location.pathname !== "/widgets") {
      onClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      logonUser &&
      logonUser?.first_name &&
      logonUser?.last_name &&
      logonUser?.first_name !== "" &&
      logonUser?.last_name !== ""
    ) {
      setUserFullName(`${logonUser.first_name} ${logonUser.last_name}`);
    } else {
      setUserFullName(logonUser?.email || "");
    }

    if (logonUser && logonUser.email) {
      setUserEmail(logonUser.email);
    }
  }, [logonUser, isAuthenticated]);

  useEffect(() => {
    if (!isFetching && data) {
      setProfilePic(data);
    }
  }, [isFetching]);

  useEffect(() => {
    if (logonUser?.email && isAuthenticated) {
      getAvatarTrigger(logonUser.email);
    }
  }, [logonUser?.email, isAuthenticated]);

  return (
    <>
      <Flex
        bg="header.bgColor"
        color={useColorModeValue("inherit", "white")}
        minH={"60px"}
        w="100%"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottomWidth={isTest ? 3 : 1}
        borderBottomColor={isTest ? appColors.testEnvironmentIndicator : ""}
        borderStyle={"solid"}
        borderTopColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        hidden={isShowMobileTab && isCommunityPage(location)}
        justifyContent="space-between"
        position="fixed"
        zIndex={3}
      >
        <Heading color="white" size={{ base: "sm", sm: "md" }}>
          Person Identity Management
        </Heading>
        <Stack justify={"flex-end"} direction={"row"} spacing={6}>
          <Menu closeOnSelect={false}>
            {!isLoading && (
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                {profilePic ? (
                  <Avatar size={"sm"} bg="gray.300" src={profilePic} />
                ) : (
                  <Avatar size={"sm"} bg="gray.300" name={userFullName || userEmail} />
                )}
              </MenuButton>
            )}

            <MenuList>
              <AuthenticatedTemplate>
                <MenuGroup title={userFullName.length > 0 ? userFullName : userEmail}>
                  <AppAuth requiredRole="*">
                    <MenuDivider />
                    <MenuItem as={RouterLink} to="/profile">
                      Edit Profile
                    </MenuItem>
                  </AppAuth>

                  <MenuDivider />
                  <MenuItem>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel
                        htmlFor="site-theme"
                        mb="0"
                        textTransform="capitalize"
                        fontWeight="normal"
                      >
                        {colorMode === "light" ? "Day" : "Night"}
                      </FormLabel>
                      <Switch id="site-theme" onChange={toggleColorMode} />
                    </FormControl>
                  </MenuItem>

                  <MenuDivider />

                  <MenuItem
                    onClick={() => {
                      instance.logoutRedirect({
                        postLogoutRedirectUri: "/",
                        account: instance.getActiveAccount(),
                      });
                      persistor.purge();
                      sessionStorage.clear();
                      localStorage.clear();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuGroup>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <MenuGroup>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      instance.loginRedirect(loginRequest);
                    }}
                  >
                    Sign In
                  </MenuItem>
                </MenuGroup>
              </UnauthenticatedTemplate>
            </MenuList>
          </Menu>
        </Stack>
      </Flex>
    </>
  );
};

export default Header;
