import { AddIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as Yup from "yup";
import {
  useLazyGetGraphUserByEmailQuery,
  usePostGraphUserMutation,
} from "../../app/services/pim/api/graph";
import { useLazyGetUserByEmailQuery } from "../../app/services/pim/api/user";
import { usePutUserDemographicByRefUserIdMutation } from "../../app/services/pim/api/userDemographics";
import { RefUserDto } from "../../app/services/pim/types";
import { PostGraphUserParam } from "../../app/services/pim/types/graphTypes";
import { PutUserDemographicParamDto } from "../../app/services/pim/types/userDemographicTypes";
import AppAuth from "../../features/AppAuth";

interface Props {
  onSuccess: (selectedUser: RefUserDto | null) => void;
}

const AddRefUserModal: FC<Props> = ({ onSuccess }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertDisclosure = useDisclosure();

  const [postAsync, { isLoading, isSuccess, isError, error }] = usePostGraphUserMutation();

  const [getGraphUserByEmailTrigger, { isFetching }] = useLazyGetGraphUserByEmailQuery();
  const [getUserByEmailTrigger] = useLazyGetUserByEmailQuery();
  const [putDemographicsAsync] = usePutUserDemographicByRefUserIdMutation();

  const initialValues: PostGraphUserParam = {
    email: "",
    first_name: "",
    last_name: "",
    sso_name: process.env.REACT_APP_SSO_NAME || "",
    source: process.env.REACT_APP_PIM_GRAPH_SOURCE || "",
  };

  const postSubmit = async (values: PostGraphUserParam) => {
    const params = {
      ...values,
      first_name: values.first_name || "-",
      last_name: values.last_name || "-",
    };
    try {
      await postAsync(params);
      let selectedUserResult = await getUserByEmailTrigger(params.email);

      //update demographics
      await putDemographicsAsync({
        ref_user_id: selectedUserResult.data?.ref_user_id,
        demographicList: [
          {
            demographic_name: "first_name",
            demographic_value: values.first_name,
          },
          {
            demographic_name: "last_name",
            demographic_value: values.last_name,
          },
        ],
      } as PutUserDemographicParamDto);

      onSuccess(selectedUserResult.data || null);
    } catch (e) {}
  };

  const FormSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid format").required("Email is required"),
    first_name: Yup.string().optional(),
    last_name: Yup.string().optional(),
  });

  const customValidateEmail = async (email: string) => {
    let result = { isValid: false, msg: "" };
    try {
      if (values.email.includes("+")) {
        result.msg = "Email is invalid format";
      } else {
        const dbUserExist = await getUserByEmailTrigger(email);
        const graphUserExist = await getGraphUserByEmailTrigger(email);

        if (dbUserExist.isSuccess) {
          result.msg = "Account exists for the Email";
        } else if (graphUserExist.isSuccess) {
          result.msg = "An SSO account already exists for the Email";
        } else {
          result.isValid = true;
        }
      }
    } catch (error) {
      result.msg = "Error validating email";
    }

    return result;
  };

  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      alertDisclosure.onClose();
      const emailValidation = await customValidateEmail(values.email);
      if (emailValidation.isValid) {
        const val = {
          ...values,
        };
        await postSubmit(val);
      } else {
        errors.email = emailValidation.msg;
      }
    },
  });

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        setTimeout(() => {
          onClose();
          resetForm();
          alertDisclosure.onClose();
        }, 2000);
      }
    }

    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    return () => {
      resetForm();
      alertDisclosure.onClose();
    };
  }, [isOpen]);

  const getAlertIcon = () => {
    if (isSuccess) return "success";
    if (isError) return "error";
    return "info";
  };
  return (
    <>
      <HStack alignItems="center">
        <Text>User</Text>
        <AppAuth requiredRole={["User.Write"]}>
          <Button leftIcon={<AddIcon />} boxShadow="sm" size="sm" onClick={onOpen}>
            Add New User
          </Button>
        </AppAuth>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <form onSubmit={handleSubmit}>
          {
            <ModalContent>
              <ModalHeader>Add New User</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mb={3} isInvalid={!!errors.email && touched.email}>
                  <FormLabel>Email</FormLabel>
                  <Input id="email" name="email" value={values.email} onChange={handleChange} />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl mb={3} isInvalid={!!errors.first_name && touched.first_name}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    id="first_name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>First Name is required</FormErrorMessage>
                </FormControl>

                <FormControl mb={3} isInvalid={!!errors.last_name && touched.last_name}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    id="last_name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>Last Name is required</FormErrorMessage>
                </FormControl>

                {alertDisclosure.isOpen && (
                  <Alert status={getAlertIcon()}>
                    <AlertIcon />
                    {isSuccess && "User Added!"}
                    {isError &&
                      (error
                        ? (error as any)?.data?.error_message
                        : "Something went wrong, please try again later or contact admin")}
                  </Alert>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  mr={3}
                  size="sm"
                  variant="solid"
                  colorScheme="brand.main"
                  type="submit"
                  isLoading={isLoading || isFetching}
                >
                  Submit
                </Button>
                <Button size="sm" onClick={onClose} disabled={isLoading || isFetching}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          }
        </form>
      </Modal>
    </>
  );
};

export default AddRefUserModal;
