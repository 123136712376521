import {
  Box,
  IconButton,
  VStack,
  Text,
  Flex,
  SimpleGrid,
  useBoolean,
  Tooltip,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useGetUserEventListQuery } from "../../app/services/pim/api/user";
import CustomTable from "../../components/CustomTable";
import { AppSSOUserDto, UserEventDto } from "../../app/services/pim/types";
import { convertUtcToLocal } from "../../app/helpers/dateHelper";
import { MdInfo } from "react-icons/md";
import TooltipLabelValue from "../../components/TooltipLabelValue";
import GridViewSwitch from "../../components/GridViewSwitch";

interface IUserEventListProps {
  selectedApp: AppSSOUserDto;
}

const UserEventList: FC<IUserEventListProps> = ({ selectedApp }) => {
  const [search] = useState("");
  const [events, setEvents] = useState<UserEventDto[] | null>(null);
  const [gridViewFlag, setGridViewFlag] = useBoolean(true);
  const { data, isLoading, isFetching } = useGetUserEventListQuery({
    ref_user_id: selectedApp.ref_user_id,
    ref_app_id: selectedApp.ref_app_id,
  });

  const TableHeader = [
    {
      Header: "Action",
      isAction: true,
      style: { display: "flex", justifyContent: "center" },
      Cell: ({ row: { original } }: { row: { original: UserEventDto } }) => {
        return (
          <>
            <Tooltip
              label={
                <TooltipLabelValue
                  labelValueList={[{ label: "ID", value: original.user_event_id }]}
                  auditFieldsObject={original}
                />
              }
            >
              <IconButton
                minWidth={1}
                variant="link"
                aria-label="View Details"
                icon={<MdInfo />}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      Header: "Event Name",
      accessor: "event_name",
    },
    {
      Header: "Last Sync Date",
      Cell: ({ row: { original } }: { row: { original: UserEventDto } }) => {
        return <>{convertUtcToLocal(original.last_sync_datetime_utc)}</>;
      },
    },
    {
      Header: "Last Sync GUID",
      Cell: ({ row: { original } }: { row: { original: UserEventDto } }) => {
        return <>{original.last_sync_guid}</>;
      },
    },
  ];

  useEffect(() => {
    if (data) {
      let tempData = data.filter((f) => f.last_sync_guid != null);
      setEvents(tempData);
    }
  }, [data]);

  return (
    <VStack align="stretch" spacing={5}>
      {!isLoading && (
        <Box
          //p={2}
          width="100%"
          // borderLeft={"1"}
          // border="1px"
          // borderColor="gray.200"
          // boxShadow="sm"
          // bg="gray.100"
        >
          <SimpleGrid
            columns={1}
            spacing={2}
            // fontSize="sm"
          >
            <Box>
              <Flex>
                <Text as="strong" pr={2} whiteSpace="nowrap">
                  App Name:
                </Text>
                <Text borderRightColor="gray.200">{selectedApp.app_name}</Text>
              </Flex>
            </Box>
            <Box>
              <Flex>
                <Text as="strong" pr={2} whiteSpace="nowrap">
                  SSO Name:
                </Text>
                <Text borderRightColor="gray.200">{selectedApp.sso_name}</Text>
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      )}
      <GridViewSwitch isChecked={gridViewFlag} onChange={setGridViewFlag.toggle} />
      <CustomTable
        variant={gridViewFlag ? "grid" : "table"}
        isLoading={isLoading}
        isFetching={isFetching}
        data={events ?? []}
        pageCount={events?.length || 0}
        pageSize={20}
        totalRecords={events?.length || 0}
        headers={TableHeader}
        search={search}
        hidePagination
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        onPageSearch={() => {}}
        onSort={() => {}}
      />
    </VStack>
  );
};

export default UserEventList;
