import { pimApi } from "./index";
import { UpdateUserRoleParam, UserRoleDto } from "../types";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoleListByRefUserId: builder.query<UserRoleDto[], number>({
      query: (ref_user_id) => `/UserRole/user/${ref_user_id}`
    }),
    getUserRoleListByEmail: builder.query<UserRoleDto[], string>({
      query: (email) => `/UserRole/email/${email}`
    }),
    putUserRole: builder.mutation<
      void,
      { ref_user_id: number; model: UpdateUserRoleParam[] }
    >({
      query: (params) => ({
        url: `/UserRole/user/${params.ref_user_id}`,
        method: "PUT",
        body: params.model
      }),
      invalidatesTags: ["UserRole"]
    })
  }),
  overrideExisting: false
});

export const {
  useGetUserRoleListByEmailQuery,
  useGetUserRoleListByRefUserIdQuery,
  usePutUserRoleMutation
} = extendedApi;
