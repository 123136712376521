import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useState } from "react";

import { useGetRefDomainListQuery } from "@/app/services/pim/api/refDomain";
import { convertUtcToLocal } from "@/app/helpers/dateHelper";

import CustomTable from "@/components/CustomTable";
import PanelCard from "@/components/PanelCard";
import AppAuth from "@/features/AppAuth";
import Modal from "./Modal";

import type { RefDomainDto } from "@/app/services/pim/types";
import type { Row } from "react-table";

const Domains = () => {
  const [search, setSearch] = useState("");

  const refDomainResult = useGetRefDomainListQuery();

  return (
    <PanelCard
      w="full"
      header={
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>Domains</h1>
          <Modal
            refreshList={refDomainResult.refetch}
            fields={{
              domain_name: "",
              domain_desc: "",
              disabled_flag: false
            }}
            trigger={
              <AppAuth requiredRole={["RefDomain.Write"]}>
                <Button ml={3} leftIcon={<AddIcon />} boxShadow="sm" size="sm">
                  Add Domain
                </Button>
              </AppAuth>
            }
          />
        </div>
      }
    >
      <CustomTable
        variant="table"
        headers={Header}
        isLoading={refDomainResult.isLoading}
        isFetching={refDomainResult.isFetching}
        data={refDomainResult.data?.data ?? []}
        pageCount={1}
        pageSize={refDomainResult.data?.data.length || 0}
        totalRecords={refDomainResult.data?.total_records || 0}
        showRecordCount
        tableSort
        search={search}
        onSort={() => {}}
        onPageChange={(index) => {}}
        onPageSizeChange={(size) => {}}
        onPageSearch={(search) => {}}
        hidePagination
        localSearch
      />
    </PanelCard>
  );
};

const Header = [
  {
    Header: "Name",
    accessor: "domain_name"
  },
  {
    Header: "Description",
    accessor: "domain_desc"
  },
  {
    Header: "Created By",
    accessor: "row_created_by_user_name"
  },
  {
    Header: "Created Date Time",
    Cell: ({ row: { original } }: { row: Row<RefDomainDto> }) => {
      return <>{convertUtcToLocal(original.row_created_datetime_utc)}</>;
    }
  },
  {
    Header: "Modified By",
    accessor: "row_modified_by_user_name"
  },
  {
    Header: "Modified Date Time",
    Cell: ({ row: { original } }: { row: Row<RefDomainDto> }) => {
      return <>{convertUtcToLocal(original.row_modified_datetime_utc)}</>;
    }
  }
];

export default Domains;
