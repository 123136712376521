import { useColorModeValue, Stack, Text } from "@chakra-ui/react";
type Props = {};

const Footer = (props: Props) => {
  return (
    <>
      <Stack
        h={63}
        w="100%"
        //mt={isCommunityPage(location) ? 0 : 9}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue("gray.50", "gray.900")}
        color={useColorModeValue("inherit", "gray.200")}
      >
        <Text fontSize="sm">&copy; {new Date().getFullYear()} Person Identity Management</Text>
      </Stack>
    </>
  );
};

export default Footer;
