import { pimApi } from "./index";

const extendedApi = pimApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvatarByEmail: builder.query<string, string>({
      query: (email) => ({
        url: `/avatar/${email}`,
        method: "GET",
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["Avatar"],
    }),
    postAvatar: builder.mutation<void, FormData>({
      query: (params) => ({
        url: "/avatar",
        method: "POST",
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAvatarByEmailQuery,
  usePostAvatarMutation,
  useLazyGetAvatarByEmailQuery,
} = extendedApi;
