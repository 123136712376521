import { IconButton } from "@chakra-ui/button";
import { Stack, HStack, Heading, Box, BoxProps } from "@chakra-ui/layout";
import { FC, ReactNode } from "react";
import { CgClose } from "react-icons/cg";

interface IPanelCardProps extends BoxProps {
  children?: ReactNode;
  header?: ReactNode;
  fullHeight?: boolean;
  onClose?: () => void;
}
const PanelCard: FC<IPanelCardProps> = ({
  children,
  header,
  fullHeight,
  onClose,
  ...otherProps
}) => {
  return (
    <Box
      p={5}
      //bgColor="white"
      shadow="md"
      border="1px"
      borderColor="gray.200"
      borderRadius={3}
      height={fullHeight ? "100%" : ""}
      {...otherProps}
    >
      <Stack direction="column">
        <HStack justifyContent="space-between">
          <Heading as="h5" size="md" mb={2} style={{ width: "100%" }}>
            {header}
          </Heading>
          {onClose && (
            <IconButton
              variant="link"
              colorScheme="gray"
              size="md"
              aria-label="Close"
              icon={<CgClose />}
              title="Close"
              onClick={onClose}
            />
          )}
        </HStack>
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
};

export default PanelCard;
