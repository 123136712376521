import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PanelCard from "../../components/PanelCard";
import { usePostChangePasswordMutation } from "../../app/services/pim/api/graph";

type Props = {};

const FormSchema = Yup.object().shape({
  current_password: Yup.string().label("Currrent Password").required(),
  new_password: Yup.string().label("New Password").required(),
  confirm_password: Yup.string()
    .label("Confirm Password")
    .required()
    .oneOf([Yup.ref("new_password"), null], "New Password and Confirm New Password must match"),
});

const initialValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const ChangePassword = (props: Props) => {
  const [postAsync, postStatus] = usePostChangePasswordMutation();
  const [postMessage, setPostMessage] = useState("");
  const { handleSubmit, errors, touched, handleChange, values, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    initialValues,
    onSubmit: (values) => {
      const { confirm_password, ...payload } = values;
      console.log("ChangePassword", { values, payload });
      setPostMessage("");
      postAsync(payload);
    },
  });

  useEffect(() => {
    console.log("ChangePassword", { postStatus });
    if (postStatus.isError) {
      setPostMessage("Error! Please check console");
    }
    if (postStatus.isSuccess) {
      resetForm();
      setPostMessage("Success! Please sign out and sign in with your new password");
    }
  }, [postStatus]);

  return (
    <form onSubmit={handleSubmit}>
      <PanelCard header="Change Password" margin="0 auto" maxW="450px">
        <VStack align="stretch" spacing={3}>
          <FormControl
            isRequired
            isInvalid={!!errors.current_password && touched.current_password}
          >
            <FormLabel htmlFor="current_password">Current Password</FormLabel>
            <Input
              id="current_password"
              placeholder="Enter Current Password"
              name="current_password"
              onChange={handleChange}
              value={values.current_password}
              type="password"
            />
            <FormErrorMessage>{errors.current_password}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.new_password && touched.new_password}>
            <FormLabel htmlFor="new_password">New Password</FormLabel>
            <Input
              id="new_password"
              placeholder="Enter New Password"
              name="new_password"
              onChange={handleChange}
              value={values.new_password}
              type="password"
            />
            <FormErrorMessage>{errors.new_password}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={!!errors.confirm_password && touched.confirm_password}
          >
            <FormLabel htmlFor="confirm_password">Confirm New Password</FormLabel>
            <Input
              id="confirm_password"
              placeholder="Confirm New Password"
              name="confirm_password"
              onChange={handleChange}
              value={values.confirm_password}
              type="password"
            />
            <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>
          </FormControl>
          {postMessage && (
            <Alert
              status={postStatus.isSuccess ? "success" : postStatus.isError ? "error" : "warning"}
            >
              <AlertIcon />
              {postMessage}
            </Alert>
          )}
          <HStack justifyContent="right">
            <Button type="submit" colorScheme="brand.main" isLoading={postStatus.isLoading}>
              Submit
            </Button>
          </HStack>
        </VStack>
      </PanelCard>
    </form>
  );
};

export default ChangePassword;
