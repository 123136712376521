import {
  SimpleGrid,
  Flex,
  Box,
  Text,
  useColorModeValue
} from "@chakra-ui/react";

import React, { FC } from "react";
import { convertUtcToLocal } from "../../../app/helpers/dateHelper";
import { RefUserDto } from "../../../app/services/pim/types";

interface IProps {
  user: RefUserDto;
}

const UserDetail: FC<IProps> = ({ user }) => {
  const textColor = useColorModeValue("inherit", "whiteAlpha.700");

  return (
    <Box width="100%">
      <SimpleGrid columns={1} spacing={2}>
        <Box>
          <Flex>
            <Text color={textColor} as="strong" pr={2}>
              Account Verified :
            </Text>
            <Text borderRightColor="gray.200">
              {user.account_verified_flag === true ? "Yes" : "No"}
            </Text>
          </Flex>
        </Box>
        <Box>
          <Flex></Flex>
        </Box>
        <Box>
          <Flex>
            <Text color={textColor} as="strong" pr={2}>
              Verified Date and Time :
            </Text>
            <Text borderRightColor="gray.200">
              {user.account_verified_datetime_utc
                ? convertUtcToLocal(user.account_verified_datetime_utc)
                : ""}
            </Text>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UserDetail;
