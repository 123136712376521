import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { useLazyGetAppSSOUserByRefUserIdQuery } from "../../app/services/pim/api/appSSOUser";
import { usePutSignInAccessMutation } from "../../app/services/pim/api/graph";
import { RefUserDto } from "../../app/services/pim/types";
import AppAuth from "../../features/AppAuth";

interface IProps {
  user: RefUserDto;
  onSuccess: (updatedUser: RefUserDto) => void;
}

const EditUserSignInAccessModal: FC<IProps> = ({ user, onSuccess }: IProps) => {
  const alertDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mode, setMode] = useState<"block" | "unblock">("unblock");
  const cancelRef = useRef(null);
  const [updateAsync, { isLoading, isSuccess, isError, error }] = usePutSignInAccessMutation();

  const [getSsoUserTrigger, { isLoading: isSsoLoading }] = useLazyGetAppSSOUserByRefUserIdQuery();

  const onSubmit = async () => {
    const ssoUser = await getSsoUserTrigger(user.ref_user_id);
    const ssoObjectId = ssoUser.data?.find((f) => f.sso_object_id)?.sso_object_id || 0;
    await updateAsync({
      sso_object_id: ssoObjectId.toString(),
      access_flag: mode === "unblock",
    });
  };

  useEffect(() => {
    if (isSuccess || isError) {
      alertDisclosure.onOpen();
      if (isSuccess) {
        onSuccess({ ...user, block_signin_flag: mode === "block" });
        setTimeout(() => {
          alertDisclosure.onClose();
          onClose();
        }, 2000);
      }
    }
    return () => {
      alertDisclosure.onClose();
    };
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isOpen) {
      setMode(user.block_signin_flag ? "unblock" : "block");
    }
    return () => {
      alertDisclosure.onClose();
    };
  }, [isOpen]);

  const DialogMessage = () => (
    <>
      {`Are you sure want to ${mode === "unblock" ? "unblock" : "block"} `}

      <Text as="strong">{user.email}</Text>

      {`${mode === "unblock" ? "" : " from signing in"}? ${
        mode === "unblock"
          ? "Once unblocked the User will be able to access the application(s) the user has access to"
          : "Once blocked the User will not have any access to application(s)"
      }.`}
    </>
  );

  const getAlertIcon = () => {
    if (isSuccess) return "success";
    if (isError) return "error";
    return "info";
  };

  return (
    <>
      <AppAuth requiredRole={["User.Write"]}>
        <Tooltip
          hasArrow
          label={user.block_signin_flag ? "Click to Unblock" : "Click to Block"}
          bg="gray.100"
          placement="top"
        >
          <IconButton
            variant="link"
            color={user.block_signin_flag ? "orange.600" : "green.500"}
            aria-label="Delete"
            icon={user.block_signin_flag ? <BsFillXCircleFill /> : <BsCheckCircleFill />}
            onClick={onOpen}
            minWidth={1}
          />
        </Tooltip>
      </AppAuth>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text textTransform="capitalize">{mode}</Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <DialogMessage />

              {alertDisclosure.isOpen && (
                <Alert status={getAlertIcon()} mt={3}>
                  <AlertIcon />
                  {isSuccess &&
                    `Successfully ${mode === "unblock" ? "unblocked" : "blocked"} user`}
                  {isError && error && (error as any).data}
                </Alert>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button size="sm" ref={cancelRef} onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                colorScheme={mode === "unblock" ? "brand.main" : "orange"}
                bg={mode === "unblock" ? "brand.500" : "orange.600"}
                onClick={onSubmit}
                ml={3}
                isLoading={isLoading || isSsoLoading}
                // disabled={alertDisclosure.isOpen}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditUserSignInAccessModal;
