import { Button } from "@chakra-ui/button";
import { AddIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddRefDemographicParam } from "../../../app/services/pim/types";
import { useDisclosure } from "@chakra-ui/hooks";
import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { VStack } from "@chakra-ui/layout";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/modal";
import { usePostRefDemographicMutation } from "../../../app/services/pim/api/refDemographic";
import { Alert, AlertIcon } from "@chakra-ui/alert";

const FormSchema = Yup.object().shape({
  demographic_name: Yup.string().label("Demographic Name").required(),
  demographic_desc: Yup.string().label("Demographic Description").required(),
  data_type: Yup.string().label("Data Type").required(),
  data_length: Yup.number()
    .min(0)
    .max(999999999)
    .nullable(true)
    .typeError("Data Length must be a number")
});

const initialValues: AddRefDemographicParam = {
  demographic_name: "",
  demographic_desc: "",
  data_type: "",
  data_length: null,
  data_format: "",
  disabled_flag: false
};

type Props = {};

const AddDemographicModal = (props: Props) => {
  const [postAsync, postStatus] = usePostRefDemographicMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState("");
  const { handleSubmit, errors, touched, handleChange, values, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: FormSchema,
      initialValues,
      onSubmit: (values) => {
        setErrorMessage("");
        postAsync(values);
      },
      onReset: () => {
        setErrorMessage("");
      }
    });

  useEffect(() => {
    if (postStatus.isSuccess) {
      resetForm();
      onClose();
    }
    if (postStatus.isError) {
      setErrorMessage((postStatus.error as any).data);
    }
  }, [postStatus]);

  const onModalClose = () => {
    resetForm();
    onClose();
  };
  return (
    <>
      <Button
        ml={3}
        leftIcon={<AddIcon />}
        boxShadow="sm"
        size="sm"
        onClick={onOpen}
      >
        Add Demographic
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Demographic</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              <VStack align="stretch" spacing={3}>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.demographic_name && touched.demographic_name
                  }
                >
                  <FormLabel htmlFor="demographic_name">
                    Demographic Name
                  </FormLabel>
                  <Input
                    id="demographic_name"
                    placeholder="Enter Demographic Name"
                    name="demographic_name"
                    onChange={handleChange}
                    value={values.demographic_name}
                  />
                  <FormErrorMessage>{errors.demographic_name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.demographic_desc && touched.demographic_desc
                  }
                >
                  <FormLabel htmlFor="demographic_desc">
                    Demographic Description
                  </FormLabel>
                  <Input
                    id="demographic_desc"
                    placeholder="Enter Demographic Description"
                    name="demographic_desc"
                    onChange={handleChange}
                    value={values.demographic_desc}
                  />
                  <FormErrorMessage>{errors.demographic_desc}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.data_type && touched.data_type}
                >
                  <FormLabel htmlFor="data_type">Data Type</FormLabel>
                  <Input
                    id="data_type"
                    placeholder="Enter Data Type"
                    name="data_type"
                    onChange={handleChange}
                    value={values.data_type}
                  />
                  <FormErrorMessage>{errors.data_type}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.data_format && touched.data_format}
                >
                  <FormLabel htmlFor="data_format">Data Format</FormLabel>
                  <Input
                    id="data_format"
                    placeholder="Enter Data Format"
                    name="data_format"
                    onChange={handleChange}
                    value={values.data_format}
                  />
                  <FormErrorMessage>{errors.data_format}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.data_length && touched.data_length}
                >
                  <FormLabel htmlFor="data_length">Data Length</FormLabel>
                  <Input
                    id="data_length"
                    placeholder="Enter Data Length"
                    name="data_length"
                    onChange={handleChange}
                    value={values.data_length || ""}
                  />
                  <FormErrorMessage>{errors.data_length}</FormErrorMessage>
                </FormControl>
                <Checkbox
                  id="disabled_flag"
                  name="disabled_flag"
                  isChecked={Boolean(values.disabled_flag)}
                  onChange={handleChange}
                  fontWeight="medium"
                >
                  Disabled
                </Checkbox>
              </VStack>
              {errorMessage && (
                <VStack align="stretch" mt={3}>
                  <Alert status="warning">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                </VStack>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={onModalClose}
                mr={3}
                disabled={postStatus.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.main"
                isLoading={postStatus.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDemographicModal;
