import { useAccount } from "@azure/msal-react";
import { Table, TableContainer, Tbody, Td, Tr, VStack } from "@chakra-ui/react";

type Props = {};

const ClaimsTable = (params: any) => {
  if (params.obj === Object(params.obj)) {
    return (
      <TableContainer>
        <Table variant="striped" size="sm">
          <Tbody>
            <>
              {Object.entries(params.obj).map(([key, value]) => {
                return (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>
                      {value === Object(value) ? (
                        <ClaimsTable obj={value} />
                      ) : value === Boolean(value) ? (
                        value.toString()
                      ) : (
                        (value as any)
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </>
          </Tbody>
        </Table>
      </TableContainer>
    );
  } else {
    return <>{params.obj}</>;
  }
};

const Claims = (props: Props) => {
  const account = useAccount();

  return (
    <VStack>
      <ClaimsTable obj={account} />
    </VStack>
  );
};

export default Claims;
