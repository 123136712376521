import { useAccount, useMsal } from "@azure/msal-react";
import { Avatar, Box, Flex, Image } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
interface ProfileProps {
  src: string;
}

const MyAvatar: FunctionComponent<ProfileProps> = (props) => {
  const [userFullName, setUserFullName] = useState("");

  const account = useAccount();

  useEffect(() => {
    const { idTokenClaims } = account as any;
    if (idTokenClaims) {
      const { given_name = "", family_name = "" } = idTokenClaims;
      if (given_name.length > 0 && family_name.length > 0)
        setUserFullName(`${given_name} ${family_name}`);
    }
  }, [account]);
  return (
    <Flex justifyContent="center">
      {props.src ? (
        <Box maxW="sm" borderRadius="full" overflow="hidden" bgColor="gray.500">
          <Image src={props.src} borderRadius="full" boxSize="100px" objectFit="cover" />
        </Box>
      ) : (
        <Avatar size={"xl"} bg="gray.300" name={userFullName} />
      )}
    </Flex>
  );
};

export default MyAvatar;
