import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoleDto } from "../services/pim/types";
interface UserRoleState {
  currentUserRoles?: UserRoleDto[];
}

const initialState: UserRoleState = {};

export const userRoleSlice = createSlice({
  name: "userRoleSlice",
  initialState,
  reducers: {
    setCurrentUserRoles: (state, action: PayloadAction<UserRoleDto[]>) => {
      state.currentUserRoles = action.payload;
    }
  }
});

export const { setCurrentUserRoles } = userRoleSlice.actions;
export default userRoleSlice.reducer;
